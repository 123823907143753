import styled from 'styled-components';
import { media } from '../../globalVariables.js';

export const Container = styled.div`
width: 95%;
margin: 0 auto;
display: flex;
flex-direction: column;
align-items: center;
${media.fonts.text}
`
export const EmployeeContainer = styled.div`
width: 95%;
margin: 0 auto;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
height: 70%;
`
export const InfoContainer = styled.div`
margin-top: 60px;
`
export const PieceContainer = styled.div`
padding-top: 20px;
padding-bottom: 20px;
margin-top: 5px;
margin-bottom: 5px;
display: flex;
flex-direction: row;
align-items: center;
`
export const Header = styled.span`
font-size: 50;
text-align: center;
`
export const SubHeader = styled.span`
font-size: 20;
text-align: center;
`
export const CheckInButton = styled.button`
border: solid 1px #53E17D;
border-radius: 10px;
width: 70%;
text-align: center;
background-color: #53E17D;
`
export const AuthContainer = styled.div`
margin: 0 auto;
width: 75%;
border: solid grey 1px;
border-radius: 10px;
margin-top: 75;
`
export const AuthHeader = styled.span`
text-align: center;
margin-top: 12;
`
export const FormContainer = styled.div`
`
export const FormText = styled.span`
text-align: center;
font-size: 15;
margin-top: 20;
`