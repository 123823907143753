export const parseCSV = (data, manager_id) => {

        const lines = data.split("\n")

        let cleanArray = [];
        
        for (let i = 0; i < lines.length; i++) {
            if (lines[i] === '') {
                console.log('Empty index discarded')
            } else {
                cleanArray.push(lines[i])
            }
        }
    
    
        let dataArray = [];


        if (cleanArray[1].split(',').length === 4) {
            //Parse CSV for managers
            for (let i = 1; i < cleanArray.length; i++) {
    
                
                let manager = cleanArray[i].split(',')
                
                
                dataArray.push({
                    name: manager[0] + ' ' + manager[1],
                    email: manager[2],
                    user_type: 'manager'
                })
            
            }
        }
        else if (cleanArray[1].split(',').length === 6) {
            //Parse CSV for employees
            for (let i = 1; i < cleanArray.length; i++) {
    
            
                let employee = cleanArray[i].split(',')                
                
                dataArray.push({
                    department: employee[0],
                    email: employee[1],
                    legal_name: employee[2] + ' ' + employee[3],
                    manager_id: manager_id,
                    phone_number: employee[4],
                    DOB: employee[5],
                    user_type: 'employee'
                })
            
            }
        }
        else {
            return 'CSV file is not formatted correctly';
        }
    
    return dataArray;  
}