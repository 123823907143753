import React from 'react';
import { useHistory } from "react-router-dom";
import { styles, StatusIcon } from './ManagerScreen.js';
import styled from 'styled-components';
//Images
import backArrow from '../../img/employee/back-arrow.svg';

const Styles = {
    Header: styled.div`
    text-align: center;
        span {
            font-size: 40px;
        }
        `,
    TeamContainer: styled.div`
    display: flex;
    justify-content: center;
    `,
    Back: styled.div`
    margin-top: 10%;
    display: flex;
    justify-content: center;
    `
}

const EmployeeGroup = (props) => {
    const history = useHistory();
    const { teamInfo } = props.location.state;

    const onClick = () => {
        history.push('/')
    }

    console.log('teaminfo:', teamInfo)
    return (
    <div>
        <Styles.Header><span>Your team info:</span></Styles.Header>
        {teamInfo.map((employee, key) => {

        const onClick = () => {
            history.push({
            pathname: '/employee',
            state: {employee}
        })
        }

        //Takes a date (num) and turns it into a clean date (Aug 24)
        // const date = new Date(employee.updated_at).toDateString().split(' ')

        return (
        <Styles.TeamContainer>
            <div key={key} style={styles.userBox} onClick={onClick}>
                <div style={{...styles.itemContainer, marginLeft: 10}}>
                    <span style={{fontSize: 35}}>{employee.legal_name}</span>
                </div>
                <div style={{...styles.itemContainer, marginRight: 10}}>
                    <StatusIcon selfChecked={employee.self_checked}/>
                </div>
            </div>
        </Styles.TeamContainer>
        )
        })}
        <Styles.Back>
            <div onClick={onClick}>
                <img src={backArrow} alt="go back"/>
            </div>
        </Styles.Back>
    </div>
        
    )
}

export default EmployeeGroup;