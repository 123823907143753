import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { checkGuestInputs, checkLoginInputs } from '../../utils/checkInputs.js';
import styled from 'styled-components';
import axios from 'axios';
import { LOGIN_ENDPOINT } from '../../globalVariables.js';

//Redux
import { useDispatch } from 'react-redux';
import { login } from '../../redux/actions/login.js';

const defaultInputs = {
    email: '',
    guestEmail: '',
    password: '',
    name: '',
    phoneNumber: '',
    project_name: ''
};

const Styles = {
    container: {

    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 50,
    },
    input: {
        fontSize: 25,
        textAlign: 'center'
    },

    label: {

    },
    responseMessage: {
        fontSize: 18,
        color: 'red',
        fontWeight: 'bold'
    },
    button: styled.input`
        -webkit-appearance: none;
        font-size: 25px;
        border: solid #b580e0 1px;
        border-radius: 10px;
        background-color: #b580e0;
        color: white;
        padding: 10px 12px 10px 12px;
    `
}

const GuestCheckInForm = (props) => {
    const [inputs, setInputs] = useState(defaultInputs);
    const [guestResponseMessage, setGuestResponseMessage] = useState('')
    const [loginResponseMessage, setLoginResponseMessage] = useState('')
    const history = useHistory();
    const dispatch = useDispatch();

    const { station_id } =  props.match.params;

    const onChange = (input, value) => {
        switch (input) {
            case 'email': 
                setInputs({...inputs, email: value.toLowerCase()})
                break;
            case 'guest email': 
                setInputs({...inputs, guestEmail: value.toLowerCase()})
                break;
            case 'name':
                setInputs({...inputs, name: value})
                break;
            case 'phoneNumber':
                setInputs({...inputs, phoneNumber: value})
                break;
            case 'password':
                setInputs({...inputs, password: value})
                break;
            case 'project_name':
                setInputs({...inputs, project_name: value})
                break;
            default:
                console.log('Unhandled change.')
        }
    }

    const onGuestSubmit = (e) => {
        console.log(inputs)

        e.preventDefault();
        setGuestResponseMessage('Setting things up...')
        const { isValid, message } = checkGuestInputs(inputs);
        
        if (isValid) {
            console.log(true)
            history.push({
                pathname: `/check-in/`,
                state: {
                    station_id: station_id,
                    isGuest: true,
                    name: inputs.name,
                    email: inputs.guestEmail,
                    phone_number: inputs.phoneNumber,
                    employee_id: null,
                    project_name: inputs.project_name,
                }
            })
            }
            else {
                setInputs({...inputs, email: '', name: '', phoneNumber: '', project_name: ''})
                setGuestResponseMessage(message)
        }
    }

    const onLoginSubmit = (e) => {
        console.log(inputs)

        e.preventDefault();

        const { isValid, message } = checkLoginInputs({email: inputs.email, password: inputs.password});

        if (isValid) {
            axios.post(LOGIN_ENDPOINT, {email: inputs.email, password: inputs.password})
            .then(res => {
                const { isAuth, token, userData, teamInfo, stations } = res.data;
                if(isAuth) {

                    const { user } = userData;
                    dispatch(login({
                        token: token,
                        userData: user,
                        teamInfo: teamInfo,
                        stationInfo: stations
                    }))
                    history.push({
                        pathname: `/check-in/`,
                        state: {
                            isGuest: false,
                            station_id: station_id,
                            project_name: inputs.project_name,
                        }
                    })
                } else {
                    setInputs({...inputs, email: '', password: '', project_name: ''})
                    setLoginResponseMessage(res.data.message);
                }
            })
        } else {
            setInputs({...inputs, email: '', password: '', project_name: ''})
            setLoginResponseMessage(message);
        }
    }

    return (
        <div>
            <form onSubmit={onLoginSubmit} style={Styles.container}>
                <div style={{...Styles.inputContainer, marginTop: 10}}>
                    <span>Login:</span>
                    <span style={Styles.responseMessage}>{loginResponseMessage}</span>
                </div>
                <div style={Styles.inputContainer}>
                    <span style={Styles.label}>Email address:</span>
                    <input
                    style={Styles.input}
                    type="text"
                    value={inputs.email}
                    onChange={(e) => onChange('email', e.target.value)}
                    />
                </div>
                <div style={Styles.inputContainer}>
                    <span style={Styles.label}>Password:</span>
                    <input
                    style={Styles.input}
                    type="password"
                    value={inputs.password}
                    onChange={(e) => onChange('password', e.target.value)}
                    />
                </div>
                <div style={Styles.inputContainer}>
                    <span style={Styles.label}>Project:</span>
                    <select id="projects"
                        onChange={(e) => onChange('project_name', e.target.value)}
                    >
                        <option name="Select Project">Select Project</option>
                        <option name="Queen Pins">Queen Pins</option>
                        <option name="I don't see my project">I don't see my project</option>
                    </select>
                </div>
                <div style={Styles.inputContainer}>
                    <Styles.button
                    type="submit"
                    value="Continue to check-in"
                    />
                </div>
            </form>
            <div style={Styles.inputContainer}>
                <span className="or-message">or</span>
            </div>
            <form onSubmit={onGuestSubmit} style={Styles.container}>
                <div style={{...Styles.inputContainer, marginTop: 10}}>
                    <span>Check in as a guest:</span>
                    <span style={Styles.responseMessage}>{guestResponseMessage}</span>
                </div>
                <div style={Styles.inputContainer}>
                    <span style={Styles.label}>Email address:</span>
                    <input
                    style={Styles.input}
                    type="text"
                    value={inputs.guestEmail}
                    onChange={(e) => onChange('guest email', e.target.value)}
                    />
                </div>
                <div style={Styles.inputContainer}>
                    <span style={Styles.label}>First {'&'} last name:</span>
                    <input
                    style={Styles.input}
                    type="text"
                    value={inputs.name}
                    onChange={(e) => onChange('name', e.target.value)}
                    />
                </div>
                <div style={Styles.inputContainer}>
                    <span style={Styles.label}>Phone number:</span>
                    <input
                    style={Styles.input}
                    type="text"
                    value={inputs.phoneNumber}
                    onChange={(e) => onChange('phoneNumber', e.target.value)}
                    />
                </div>
                <div style={Styles.inputContainer}>
                    <span style={Styles.label}>Project:</span>
                    <select id="projects"
                        onChange={(e) => onChange('project_name', e.target.value)}
                    >
                        <option name="Select Project">Select Project</option>
                        <option name="Queen Pins">Queen Pins</option>
                        <option name="I don't see my project">I don't see my project</option>
                    </select>
                </div>
                <div style={Styles.inputContainer}>
                    <Styles.button
                    type="submit"
                    value="Continue to check-in"
                    />
                </div>
            </form>
        </div>
        
    )
}

export default GuestCheckInForm;