import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/logout.js'


const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20
    },
    logoutContainer: {
        width: "40%",
        padding: 5,
        borderWidth: 1,
        borderRadius: 10,
        backgroundColor: '#b580e0',
        borderColor: '#b580e0',
        marginTop: 20,
        marginBottom: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logoutText: {
        fontSize: 15,
        color: 'white'
    }
}

const LogoutFooter = () => {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(logout());
    }

    return (
        <div style={styles.logoutContainer} onClick={onClick}>
            <span style={styles.logoutText}>Logout</span>
        </div>
    )
}

export default LogoutFooter;