import { LOGIN, LOGOUT, UPDATE_TEAM_INFO, UPDATE_SELF_CHECK, UPDATE_STATIONS, REHYDRATE } from '../types.js'

// For production
export const initialApplicationState = {
    token: null,
    userData: {
        user_type: null
    },
    companies: [],
    teamInfo: [],
    stationInfo: [],
    production: [],
    isFetched: false
};

//For employee dev
// const initialState = {
//     token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsbGVuYmVuQHByb3Rvbm1haWwuY29tIiwidXNlcklkIjo5OSwidXNlclR5cGUiOiJlbXBsb3llZSIsImlhdCI6MTYwMzM5ODUyMCwiZXhwIjoxNjAzNDI3MzIwfQ.VNhVKseLIvE_AcDjf6CVgLfGBTjPZ4ECzmC9MYVxwEI',
//     userData: {
//         DOB: "07/30/1997",
//         check_in: null,
//         consent_form_signed: 0,
//         created_at: "2020-10-22 20:22:17",
//         department: "Production",
//         email: "allenben@protonmail.com",
//         gender: null,
//         id: 99,
//         lab_id: null,
//         legal_name: "Ben Allen",
//         manager_id: 1,
//         password: "901100",
//         phone_number: "2817443025",
//         physician_checked: 0,
//         physician_id: null,
//         self_checked: "not complete",
//         testing_time: null,
//         updated_at: null,
//         user_type: "employee",
//     },
//     teamInfo: []
// };

//For admin dev
// const initialState = {
//     token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluMUBlbWFpbC5jb20iLCJ1c2VySWQiOjEsInVzZXJUeXBlIjoiYWRtaW4iLCJpYXQiOjE2MDMzOTc5NjcsImV4cCI6MTYwMzQyNjc2N30.20_uyDcNrchdmW8Hmffs8ui8XHWOtxxYezFxAvx3XFc',
//     userData: {
//         company: "Netflix",
//         created_at: "2020-10-15 14:50:53",
//         department: "Production",
//         email: "admin1@email.com",
//         first: "John",
//         id: 1,
//         last: "Smith",
//         password: "195857",
//         project: "Don't Look Up",
//         updated_at: null,
//         user_type: "admin"
//     },
//     teamInfo: []
// };

//For manager dev
// const initialState = {
//     token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hbmFnZXIxQGVtYWlsLmNvbSIsInVzZXJJZCI6MSwidXNlclR5cGUiOiJtYW5hZ2VyIiwiaWF0IjoxNjAzNjcyODkwLCJleHAiOjE2MDM3MDE2OTB9.pb5J7AK5iWG7d8qq2Q-1Y_Dhawyx2e7QRxDY26-TpMc',
//     userData: {
//         email: "manager1@email.com",
//         id: 1,
//         name: "Kyle Mathis",
//         password: "195857",
//         user_type: "manager"
//     },
//     teamInfo: [{
//         DOB: "07/30/1997",
//         check_in: null,
//         consent_form_signed: 0,
//         created_at: "2020-10-15 14:50:53",
//         department: "Production",
//         email: "email1@email.com",
//         gender: null,
//         id: 1,
//         lab_id: null,
//         legal_name: "John Smith",
//         manager_id: 1,
//         password: "195857",
//         phone_number: "2817443025",
//         physician_checked: 0,
//         physician_id: null,
//         self_checked: "not complete",
//         testing_time: null,
//         updated_at: null,
//         user_type: "employee"
//     }],
//     stationInfo: [{id: 1, manager_id: 1, station_name: "Apple Tent #4"}]
// };

const applicationReducer = (state = initialApplicationState, action) => {
    switch (action.type) {
        case LOGIN:
            localStorage.setItem('state', JSON.stringify({application: {...state,
                token: action.payload.token,
                userData: action.payload.userData,
                teamInfo: action.payload.teamInfo,
                stationInfo: action.payload.stationInfo,
                companies: action.payload.companies,
                managers: action.payload.managers,
                isFetched: action.payload.isFetched,
                production: action.payload.production
            }}))
            return (
                console.log('(Redux) Login success.'),
                    {...state,
                        token: action.payload.token,
                        userData: action.payload.userData,
                        teamInfo: action.payload.teamInfo,
                        stationInfo: action.payload.stationInfo,
                        companies: action.payload.companies,
                        managers: action.payload.managers,
                        isFetched: action.payload.isFetched,
                        production: action.payload.production
                    }
                )
        case LOGOUT:
            localStorage.removeItem('state')
            return (
                console.log('(Redux) Logout success.'),
                    {...state,
                        token: action.payload.token,
                        userData: action.payload.userData,
                        teamInfo: action.payload.teamInfo,
                        stationInfo: action.payload.stationInfo
                    }
                )
        case UPDATE_TEAM_INFO:
            localStorage.setItem('state', JSON.stringify({...state,
                teamInfo: action.payload.teamInfo
            }))

            return (
                console.log('(Redux) Updating team info.'),
                    {...state,
                        teamInfo: action.payload.teamInfo
                    }
                )
        case UPDATE_SELF_CHECK:
            localStorage.setItem('state', JSON.stringify({...state,
                userData: {
                    self_checked: action.payload.self_checked
                },
            }))

            return (
                console.log('(Redux) Updating self check.'),
                    {...state,
                        userData: {
                            self_checked: action.payload.self_checked
                        },
                    }
                )
        case UPDATE_STATIONS:
            localStorage.setItem('state', JSON.stringify({...state,
                stationInfo: action.payload.stationInfo,
            }))

            return (
                console.log('(Redux) Updating station info.'),
                    {...state,
                        stationInfo: action.payload.stationInfo,
                    }
                )
        // case UPDATE_MANAGERS:
        //     localStorage.setItem('state', JSON.stringify({...state,
        //         managers: action.payload.managers.managers,
        //     }))

        //     return (
        //         console.log('(Redux) Updating manager info.'),
        //             {...state,
        //                 managers: action.payload.managers.managers,
        //             }
        //         )
        case REHYDRATE:
            return (
                console.log('(Redux) Rehydrating state.'),
                    {...state,
                    ...action.payload.rehydration.application
                    }
                )
        default:
            return {...state};
    }
};

export default applicationReducer;