export const checkEmployeeCreation = (inputs) => {
    if (inputs.legal_name.length >= 60) {
        return {
            isValid: false,
            message: 'Employee name is too long.'
        }
    }
    else if (!inputs.email.includes('@' && '.')) {
        return {
            isValid: false,
            message: 'Please provide a valid email.'
        }
    }
    else if (inputs.phone_number === '') {
        return {
            isValid: false,
            message: 'Enter your phone number.'
        }
    }
    else if (inputs.phone_number.length >= 11) {
        return {
            isValid: false,
            message: 'Enter a valid phone number.'
        }
    }
    else if (inputs.department === '') {
        return {
            isValid: false,
            message: 'Enter a valid department.'
        }
    }
    else if (inputs.department.length >= 60) {
        return {
            isValid: false,
            message: 'Department name is too long.'
        }
    }
    else {
        return {
            isValid: true
        }
    }

}