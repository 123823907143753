import React from 'react';
import { Link } from 'react-router-dom';

//Icons
import positiveResult from '../../img/result-icons/positiveResult.svg';
import negativeResult from '../../img/result-icons/negativeResult.svg';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh'
    },
    image: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 150
    },
    message: {
        fontSize: 35,
        textAlign: 'center',
        color: 'white'
    },
    nextCheck: {
        fontSize: 25,
        textAlign: 'center'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: 300,
        paddingTop: 50
    },
    button: {
        width: "80%",
        padding: 20,
        border: 'solid white 1px',
        borderRadius: 10,
        marginTop: 20,
        marginBottom: 50,
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        fontSize: 30,
        textDecoration: 'none'
    },
    downloadHeaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    downloadHeader: {
        fontSize: 30,
        textAlign: 'center',
        marginBottom: 10

    },
    downloadSubHeader: {
        fontSize: 20,
        marginBottom: 20
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
}

const initialData = {
    isCreated: false,
}

const TransportResult = (props) => {

    const { isCreated } = props.location.state || initialData;

    
    if (isCreated) {
        return (
            <div style={{...styles.container, backgroundColor: '#70c041'}}>
                <div style={styles.image}>
                    <img src={positiveResult} alt="Positive result"/>
                </div>
                <span style={styles.message}>You've been checked out!</span>
                <span style={{...styles.message, fontSize: 25}}>Have a great day!</span>
                <div style={styles.buttonContainer}>
                    <Link style={styles.button} to="/check-in/transport/">Got It</Link>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{...styles.container, backgroundColor: 'red'}}>
                <div style={styles.image}>
                    <img src={negativeResult} alt="Negative result"/>
                </div>
                <span style={styles.message}>Oops! There was an error.</span>
                <span style={{...styles.message, fontSize: 25}}>Record your transport and speak to manager.</span>
                <div style={styles.buttonContainer}>
                    <Link style={styles.button} to="/check-in/transport/">Got It</Link>
                </div>
                
            </div>
        )
    }
}

export default TransportResult;