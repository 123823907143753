import { UPDATE_MANAGERS } from '../types.js';

export const updateManagers = (values) => {
    const { managers } = values;
    return {
        type: UPDATE_MANAGERS,
        payload: {
            managers: managers
        }
    }
}
