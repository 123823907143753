import React from 'react';
import styled from 'styled-components';
import { media } from '../../globalVariables.js';

//Components
import { Container } from '../Employees/styles.js'
import { StatusIcon } from './ManagerScreen.js';

//Images
import phone from '../../img/homepage/Manager/phone.svg';
import message from '../../img/homepage/Manager/message.svg';

const styles = {
    lastCheck: {
        fontSize: 35
    },
    buttonText: {
        padding: 20,
        textAlign: 'center',
        color: 'white',
        fontSize: 30
    },
    statusContainer: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    statusText: {
        fontSize: 20
    },
    disabledButton: {
        display: 'none'
    },
    contactHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 100
    },
    contact: {
        borderColor: '#b580e0',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    deptContainer: {
        displat: 'flex',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20
    },
    deptHeader: {
        fontSize: 25
    },
    dept: {
        fontSize: 20
    }
}

const Styles = {
    Header: styled.div`
    span {
        font-size: 20px;
    }

    img {
        max-width: 20%;
    }
    `,
    Status: styled.div`
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10%;

    span {
        font-size: 15px;
    }

    img {
        max-width: 30%;
    }
    `,
    Department: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;

    span {
        font-size: 15px;
        ${media.fonts.text}
    }
    `,
    Contact: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;

    .contactContainer {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin-top: 10%;

        img {
            max-width: 50%;
            margin: 0 auto;
        }
    }

    span {
        margin: 0 auto;
        ${media.fonts.text}
    }

    
    `
}
const IndividualEmployee = (props) => {
    const { member } = props;

    if (props.display) {
        return (
            <div>
                <Container>
                    <Styles.Header><span>{member.legal_name}</span></Styles.Header>
                    <Styles.Status>
                        <span>Status:</span>
                        <StatusIcon selfChecked={member.self_checked}/>
                    </Styles.Status>
                </Container>
                <Styles.Department>
                    <span>Department:</span>
                    <span>{member.department}</span>
                </Styles.Department>
                <Styles.Contact>
                    <span style={{fontSize: 25}}>Need to contact?</span>
                    <div className="contactContainer">
                            <a href={`tel:${member.phone_number}`} >
                                <div style={styles.contact}>
                                    <img src={phone} alt="text employee"/>
                                </div>
                            </a>
                            <a href={`sms:${member.phone_number}`} >
                                <div style={styles.contact}>
                                    <img src={message} alt="text employee"/>
                                </div>
                            </a>
                        </div>
                </Styles.Contact>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
    
}

export default IndividualEmployee;