import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { rehydrate } from '../redux/actions/rehydrate.js';

//Components
import Login from '../Components/Login.js';
import CheckInForm from '../Components/Check-In/CheckInForm.js';
import TransportCheckIn from '../Components/Check-In/TransportCheckIn.js';
import TransportWaiting from '../Components/Check-In/TransportWaiting.js';
import TransportResult from '../Components/Check-In/TransportResult.js';
import QRCodeGenerator from '../Components/QRCode.js';

    //Managers
    import ManagerScreen from '../Components/Manager/ManagerScreen.js';
    import EmployeeGroup from '../Components/Manager/EmployeeGroup.js';
    import StationDashboard from '../Components/Manager/StationDashboard.js';
    import MyTeam from "../Components/Manager/MyTeam.js";

    //Employees
    import Home from '../Components/Employees/Home.js'
    import CheckIn from '../Components/Check-In/CheckIn.js';
    import CheckInResult from '../Components/Check-In/CheckInResult.js';

    //Admin
    import AdminHome from '../Components/Admin/AdminHome.js';
    import Companies from '../Components/Admin/Companies.js';
    import Managers from '../Components/Admin/Managers.js';



const Routes = () => {
    const dispatch = useDispatch();
    const rawPersistedState = localStorage.getItem('state')

    //Redux State
    const state = useSelector(state => state)
    console.log(`State @ Router:`);
    console.log(state)
    const { application } = state;

    //Persisted State
    const persistedState = JSON.parse(rawPersistedState)
    console.log('Persisted State @ Router:')
    console.log(persistedState)


//Rehydrates state if token is empty
if (persistedState !== null) {
    if (typeof persistedState.application !== 'undefined') {
        if (typeof persistedState.application.token !== 'undefined') {
            if (application.token === null && persistedState.application.token) {
                console.log('Attempting to rehydrate state.')
                dispatch(rehydrate(persistedState))
            }
        }
    }
}

    switch (application.userData.user_type) {
        case 'admin':
            return (
                <Switch>
                    <Route exact path="/" component={AdminHome}></Route>
                    <Route exact path="/check-in/" component={CheckIn}></Route>
                    <Route exact path = "/companies" component={Companies}></Route>
                    <Route exact path = "/managers" component={Managers}></Route>
                    <Route path="*">
                        <Redirect to="/"/>
                    </Route>
                </Switch>
            )
        case 'employee':
            return (
            <Switch>
                <Route exact path="/" component={Home}></Route>
                <Route exact path="/check-in/auth/:station_id" component={CheckInForm}></Route>
                <Route exact path="/check-in/" component={CheckIn}></Route>
                <Route exact path="/check-in/result" component={CheckInResult}></Route>
                <Route path="*">
                        <Redirect to="/"/>
                </Route>
            </Switch>
            )
        case 'manager':
            return(
                <Switch>
                    <Route exact path="/" component={ManagerScreen}></Route>
                    <Route exact path="/check-in/" component={CheckIn}></Route>
                    <Route exact path="/team" component={MyTeam}></Route>
                    <Route exact path="/employee-group" component={EmployeeGroup}></Route>
                    <Route exact path="/station" component={StationDashboard}></Route>
                    <Route exact path="/qr-code/:id" component={QRCodeGenerator}></Route>
                    <Route path="*">
                        <Redirect to="/"/>
                    </Route>
                </Switch>
            )
        default:
            return (
                <Switch>
                    <Route exact path="/" component={Login}></Route>
                    <Route exact path="/check-in/auth/:station_id" component={CheckInForm}></Route>
                    <Route exact path="/check-in/" component={CheckIn}></Route>
                    <Route exact path="/check-in/result" component={CheckInResult}></Route>
                    <Route exact path="/check-in/transport" component={TransportCheckIn}></Route>
                    <Route exact path="/check-in/transport/check-out" component={TransportWaiting}></Route>
                    <Route exact path="/check-in/transport/result" component={TransportResult}></Route>
                    <Route path="*">
                        <Redirect to="/"/>
                    </Route>
                </Switch>
            )
    }
};

export default Routes;