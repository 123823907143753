import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { TRANSPORT_ENDPOINT } from '../../globalVariables.js';

//Redux
import { useSelector } from 'react-redux';

//Styled Components
import { Container, AuthContainer, AuthHeader, FormText, FormContainer, Input } from '../loginStyles.js'

//Images
import logo from '../../img/logo.jpg';
import van from '../../img/transport/van.svg';

const Styles = {
    guestCheckContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10
    },
    link: {
        textDecoration: 'none',
        textAlign: 'center',
        fontSize: 25,
        width: '60%',
        border: 'solid #b580e0 1px',
        borderRadius: '10px',
        backgroundColor: '#b580e0',
        color: 'white',
        paddingTop: 10,
        paddingBottom: 10
    },
    logoContainer: {
        width: '50%',
        margin: '0 auto',
        marginTop: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logoText: {
    },
    logo: {
        width: '100%'
    },
    input: styled.input`
        min-width: 80%;
        -webkit-appearance: none;
        font-size: 25px;
        border: solid #b580e0 1px;
        border-radius: 10px;
        background-color: #b580e0;
        color: white;
        padding: 5px 7px 5px 7px;
        margin-top: 20px;
    `
}

const TransportCheckIn = () => {
    const state = useSelector(state => state)

    const [inputs, setInputs] = useState({
        email: localStorage.getItem('email'),
        name: localStorage.getItem('name'),
        phoneNumber: localStorage.getItem('phoneNumber'),
        checkIn: localStorage.getItem('checkIn')
    });
    const history = useHistory();

    console.log(state)

    const onChange = (input, value) => {
        switch (input) {
            case 'email':
                setInputs({...inputs, email: value})
                break;
            case 'name':
                setInputs({...inputs, email: value})
                break;
            case 'phone number':
                setInputs({...inputs, email: value})
                break;
            default:
                setInputs({...inputs});
        }
        if (input === 'email') {
            setInputs({...inputs, email: value})
        } else {setInputs({...inputs, password: value})}
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const confirm = window.confirm('Confirm check out?');
        console.log(confirm)
        if(confirm) {
            axios.post(TRANSPORT_ENDPOINT, {...inputs, checkOut: Date.now()})
            .then(res => {
                history.push({
                    pathname: '/check-in/transport/result',
                    state: {isCreated: res.data.isCreated}
                });
            })
            .catch(err => {
                console.log(err)
            })
        }
    }

    return (
    <Container>
        <AuthContainer>
            <AuthHeader>
                Confirm your info below:
            </AuthHeader>
            <img src={van} alt="van"/>
            <FormContainer onSubmit={onSubmit}>
            <Input>
            <FormText>Email:</FormText>
            <input
                type="text"
                // style={inputStyle}
                value={inputs.email}
                onChange={(e) => onChange('email', e.target.value)}
            />
            </Input>
            <Input>
            <FormText>Name:</FormText>
            <input
                type="text"
                // style={inputStyle}
                value={inputs.name}
                onChange={(e) => onChange('name', e.target.value)}
            />
            </Input>
            <Input>
            <FormText>Phone #:</FormText>
            <input
                type="text"
                // style={inputStyle}
                value={inputs.phoneNumber}
                onChange={(e) => onChange('phone number', e.target.value)}
            />
            </Input>
            <Styles.input
             type="submit"
             value="CHECK OUT"
             disabled={false}
             />
            </FormContainer>
        </AuthContainer>
        <div style={Styles.logoContainer}>
            <span style={Styles.logoText}>Powered by:</span>
            <img style={Styles.logo} src={logo} alt="MPA"/>
        </div>
   </Container>
    )
}

export default TransportCheckIn;