import { TRANSPORT_CHECKIN } from '../types.js';

export const checkInTransport = (values) => {
    const { email, name, phoneNumber, checkIn } = values;

    return {
        type: TRANSPORT_CHECKIN,
        payload: {
            email: email,
            name: name,
            phoneNumber: phoneNumber,
            checkIn: checkIn,
            checkOut: null
        }
    }
}
