import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { media, version } from '../../globalVariables.js';

//Images
import company from '../../img/admin-menu/company.svg';
import home from '../../img/menu/home.svg';
import logoutIcon from '../../img/menu/logout.svg';
import logo from '../../img/logo.jpg';
import manager from '../../img/admin-menu/manager.svg';

//Redux
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/logout.js';

const Styles = {
    Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: solid lightgrey 1px;
    width: 20%;
    min-height: 100vh;
    background-color: white;

    .logo {
        max-width: 70%;
    }
    
    .powered-by {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 5%;
        margin-top: 5%;

        span {
            font-size: 12px;
            ${media.nonSelectable};
        }
    }

    .version {
        ${media.nonSelectable};
        font-size: 10px;
        color: lightgrey;
    }

    .fade-in {
        animation: fadeIn ease 10s;
        -webkit-animation: fadeIn ease 10s;
        -moz-animation: fadeIn ease 10s;
        -o-animation: fadeIn ease 10s;
        -ms-animation: fadeIn ease 10s;
        }

        @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-moz-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-webkit-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-o-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-ms-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
    `,
    Selection: styled.div`
    border-radius: 10px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;

    @keyframes bubbleText {
        0% {font-size: 20px;}
        50% {font-size: 18px;}
        100% {font-size: 20px;}
    }

    img {
        max-width: 20%;
    }

    span {
        font-size: 20px;
        ${media.nonSelectable};
    }

    :hover {
        min-width: 45%;
        box-shadow: 1px 0px 5px 5px #85b2ff;
        background-color: #5393FE;

        span {
            color: white;
            animation: bubbleText .5s;
        }
    }
    `
}

const Menu = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onClick = (type) => {
        switch (type) {
            case('home'):
                history.push('/')
            break;
            case('company'):
                history.push('/companies')
            break;
            case('manager'):
                history.push('/managers')
            break;
            case('logout'):
                if(window.confirm('Are you sure you want to logout?')) {
                    dispatch(logout())
                    history.push('/')
                } else {
                    //pass
                }
            break;
            default:
                return;
        }
    }

    return (
        <Styles.Container>
            <Styles.Selection onClick={() => onClick('home')}>
                <img src={home} alt="go home"/>
                <span>Home</span>
            </Styles.Selection>
            <Styles.Selection onClick={() => onClick('company')}>
                <img src={company} alt="my companies"/>
                <span>Companies</span>
            </Styles.Selection>
            <Styles.Selection onClick={() => onClick('manager')}>
                <img src={manager} alt="my managers"/>
                <span>Managers</span>
            </Styles.Selection>
            <Styles.Selection onClick={() => onClick('logout')}>
                <img src={logoutIcon} alt="logout"/>
                <span>Logout</span>
            </Styles.Selection>
            <div className="powered-by">
                <span className="fade-in">powered by:</span>
                <img className="logo fade-in" src={logo} alt="Medical Placement Assoc."/>
            </div>
            <span className="version fade-in">{version}</span>
        </Styles.Container>
    )
}

export default Menu;