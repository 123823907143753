import React from 'react';
import { CheckInButton } from './styles.js';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { StatusIcon } from '../Manager/ManagerScreen.js';
import styled from 'styled-components';

//Components
import LogoutFooter from '../UtilComponents/LogoutFooter.js';

const styles = {
    lastCheck: {
        fontSize: 35
    },
    buttonText: {
        padding: 20,
        textAlign: 'center',
        color: 'white',
        fontSize: 30
    },
    disabledButton: {
        display: 'none'
    },
    statusContainer: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    statusText: {
        fontSize: 35
    },
    departmentContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 100
    },
    departmentHeader: {
        fontSize: 25
    },
    departmentText: {
        fontSize: 30
    }
}

const Styles = {
    Container: styled.div`
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 70%;

    `,
    Header: styled.div`
    text-align: center;
    margin-bottom: 10%;

    span {
        font-size: 40px;
        margin: 0 auto;
    }
    `,
    StatusContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    
    span {
        font-size: 35px;
    }

    img {
        width: 50%;
    }
    `
}

const EmployeeScreen = () => {
    const history = useHistory();
    const { token, userData } = useSelector(state => state.application)
    const user = userData;
    const { self_checked } = user;

    const isUserChecked = (self_checked === ('passed' || 'failed'))

    const onClick = () => {
        history.push({
            pathname: '/check-in',
            state: {
                token: token
            }
        })
    }

    return (
        <Styles.Container>
            <Styles.Header><span>{user.legal_name}</span></Styles.Header>
            <CheckInButton
            onClick={onClick}
            style={isUserChecked ? styles.disabledButton : null}
            >
                <span style={styles.buttonText}>Start Check In</span>
            </CheckInButton>
            <Styles.StatusContainer>
                <span>Status:</span>
                <StatusIcon selfChecked={self_checked}/>
            </Styles.StatusContainer>
             <div style={styles.departmentContainer}>
                <span style={styles.departmentHeader}>Department:</span>
                <span style={styles.departmentText}>{user.department}</span>
             </div>
             <LogoutFooter/>
        </Styles.Container>
     )
}

export default EmployeeScreen;