import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media, style, ADD_MANAGER, FETCH_MANAGERS } from '../../globalVariables.js';
import axios from 'axios';

//Images
import addButton from '../../img/stations/add-stations.svg';
import backArrow from '../../img/employee/back-arrow.svg';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateManagers } from '../../redux/actions/updateManagers.js'; 

//Components
import Menu from './Menu.js';

const Styles = {
    Container: styled.div`
    display: flex;

    span {
        ${media.nonSelectable};
    }
    `,
    Body: styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    `,
    Header: styled.div`
    width: 100%;
    height: 40px;

    .add-button {
        width: 40px;
    }

    border-bottom: dashed lightgrey 1px;
    `,
    ManagerContainer: styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2%;
    `,
    Manager: styled.div`
    border: solid lightgrey 1px;
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    align-items: center;
    width: 200px;
    height: 45px;
    border-radius: 20px;
    box-shadow: 5px 2px #ECEBEB;

    :hover {
        border: solid grey 1px;
        background-color: ${style.grey};
    }
    `,
    AddManager: styled.div`
    border: solid lightgrey 1px;
    border-radius: 20px;
    box-shadow: 5px 2px #ECEBEB;

    width: 60%;
    `,
    Form: styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid lightgrey 1px;
    width: 60%;
    height: 90%;
    margin: 0 auto;

    .header {
        font-size: 35px;
        margin-top: 2%;
        margin-bottom: 1%;
    }

    img {
        width: 10%;
        margin-bottom: 6%;
    }
    
    .input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 5%;

        input {
            font-size: 20px;
            text-align: center;
        }

        span {
           font-size: 20px; 
        }

        .sub-text {
            font-style: italic;
            font-size: 10px;
        }
    }

    .submit {
        width: 33%;
        height: 30px;
    }
    `
}

const defaultManagerDetails = {
    name: null,
    email: null,
    confirmEmail: null
}

const Managers = () => {
    const dispatch = useDispatch();
    const { managers, token } = useSelector(state => state.application);
    const [isAddingManager, setIsAddingManager] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [managerDetails, setManagerDetails] = useState(defaultManagerDetails)
    const [responseMessage, setResponseMessage] = useState('');

    useEffect(() => {
        axios.get(FETCH_MANAGERS + token)
        .then(result => {
            dispatch(updateManagers({managers: result.data.managers}))
        })
        .catch(err => {
            console.log(err);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    function addManager() {
        setIsAddingManager(true);
        setIsSubmitting(false);
    }

    function onClose(type) {
        switch (type) {
            case 'adding manager':
                setIsAddingManager(false);
            break;
            default:
                return;
        }
    }

    function onChange(value, type) {
        switch (type) {
            case 'name':
                setManagerDetails({...managerDetails, name: value})
            break;
            case 'email':
                setManagerDetails({...managerDetails, email: value})
            break;
            case 'confirm email':
                setManagerDetails({...managerDetails, confirmEmail: value})
            break;
            default:
                return;
        }
    }

    function validateInputs(inputs) {
        const { name, email, confirmEmail } = inputs;
        if (name === '' || email === '' || confirmEmail === '') {
            return {
                isValid: false,
                issue: 'Please fill out all fields.'
            }
        }
        else if (name.length > 71) {
            return {
                isValid: false,
                issue: 'Name is too long.'
            }
        }
        else if (email !== confirmEmail) {
            return {
                isValid: false,
                issue: 'Emails do not match.'
            }
        }
        else if (!email.includes('@' || '.')) {
            return {
                isValid: false,
                issue: 'Email is not valid.'
            }
        }
        else {return {isValid: true}}
    }

    function onSubmit (e) {
        e.preventDefault();
        setIsSubmitting(true);

        const validation = validateInputs(managerDetails);

        if (validation.isValid) {
            delete managerDetails.confirmEmail;
            axios.post(ADD_MANAGER + token, {managerDetails})
            .then(response => {
                console.log(response.data)
                if(response.data.isInserted) {
                    setResponseMessage('Manager added!');
                    setManagerDetails(defaultManagerDetails);
                    setIsSubmitting(false)
                } else {
                    setResponseMessage('We encountered an error. Try again.');
                    setIsSubmitting(false);
                }
            })

        } else {
            setManagerDetails(defaultManagerDetails);
            setResponseMessage(validation.issue);
            setIsSubmitting(false);
        }
    }

    if (isAddingManager) {
        return (
            <Styles.Container>
                <Menu/>
                <Styles.Body>
                    <Styles.Header>
                        <img
                        className="add-button"
                        src={addButton}
                        alt="add manager"
                        onClick={addManager}
                        disabled={true}
                        style={{opacity: .3}}
                        />
                    </Styles.Header>
                        <Styles.Form onSubmit={onSubmit}>
                            <span className="header">Add a Manager</span>
                            <img src={backArrow} onClick={() => onClose('adding manager')} alt="go back"/>
                            <span>{responseMessage}</span>
                            <div className="input-container">
                                <span>Manager Name:</span>
                                <input
                                    type="text"
                                    value={managerDetails.name}
                                    onChange={(e) => onChange(e.target.value, 'name')}
                                />
                            </div>
                            <div className="input-container">
                                <span>Manager Email:</span>
                                <input
                                    type="text"
                                    value={managerDetails.email}
                                    onChange={(e) => onChange(e.target.value, 'email')}
                                />
                            </div>
                            <div className="input-container">
                                <span>Confirm Manager Email:</span>
                                <input
                                    type="text"
                                    value={managerDetails.confirmEmail}
                                    onChange={(e) => onChange(e.target.value, 'confirm email')}
                                />
                            </div> 
                            <input
                                className="submit"
                                type="submit"
                                value="SUBMIT"
                                disabled={isSubmitting}
                            />
                    </Styles.Form>
                </Styles.Body>
            </Styles.Container>
        )
    } else {
        return (
            <Styles.Container>
                <Menu/>
                <Styles.Body>
                    <Styles.Header>
                        <img
                        className="add-button"
                        src={addButton}
                        alt="add manager"
                        onClick={addManager}
                        />
                    </Styles.Header>
                    <Styles.ManagerContainer>
                        {managers.map((manager, index) => {
                            return (
                                <Styles.Manager>
                                    <span>{manager.name}</span>
                                    <span>{manager.id}</span>
                                </Styles.Manager>
                            )
                        })}
                    </Styles.ManagerContainer>
                </Styles.Body>
            </Styles.Container>
        )
    }

    
}

export default Managers;