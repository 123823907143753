export const questions = {
    hadCloseContact: 'In the last 14 days have you had close contact (within 6 feet for more than 15 mins) with a confirmed or probable COVID-19 (coronavirus) case?',
    travelExposure: 'In the last 14 days have you traveled outside of your country, state or province or had close contact with someone who has traveled outside of their country, state or province and is experiencing symptoms of respiratory illness (fever, cough, shortness of breath)?',
    preventable: 'Is there anything preventing you from returning to work as scheduled?',
    multipleQuestions: 'Do you currently have, or have you had in the last 3 days any of the following?',
    persistentCough: 'Persistent cough.',
    breathShortness: 'Shortness of breath or difficulty breathing.',
    highTemp: 'Temperature above 100 degrees (F).',
    senseLoss: 'New loss of taste or smell.',
    otherSymptoms: 'Chills, muscle pain or sore throat.',
    tempCheck: 'Please use an at-home temperature device to formally take your temperature. Is your temperature greater than 100F (38C)? If no access to thermometer choose No.',
    confirm: 'By providing answers to this questionnaire, you confirm that all answers are accurate and allow a WorkSafe representative to have access to these answers.'
}