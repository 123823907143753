import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { questions } from '../questions.js';
import { CHECKIN_URL } from '../../globalVariables.js'; 

//Icons
import closeContact from '../../img/question-icons/closeContact.svg';
import preventable from '../../img/question-icons/preventable.svg';
import tempCheck from '../../img/question-icons/tempCheck.svg';
import travelExposure from '../../img/question-icons/airTravel.svg'
import multipleQuestions from '../../img/question-icons/multipleQuestions.svg'
import confirm from '../../img/question-icons/confirm.svg';

//Redux
import { useSelector } from 'react-redux';


const styles = {
    container: {
        textAlign: 'center'
    },
    questionContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        marginTop: 150,
        marginBottom: 150,
    },
    smallQuestionContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 20
    },
    question: {
        fontSize: 20,
        marginTop: 40
    },
    smallQuestion: {
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 20,
    },
    image: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        width: "40%",
        padding: 20,
        borderWidth: 1,
        borderRadius: 10,
    },
    smallButton: {
        width: "20%",
        padding: 10,
        borderWidth: 1,
        borderRadius: 10,
    },
    buttonClicked: {
        backgroundColor: '#63C5DA',
        borderColor: 'white'
    },
    whitespace: {
        marginTop: 50,
        marginBottom: 50
    },
    confirmButton: {
        width: "80%",
        padding: 20,
        borderWidth: 1,
        borderRadius: 10,
        backgroundColor: '#63C5DA',
        marginTop: 20,
        marginBottom: 50
    },
    skipContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        marginTop: 150,
        marginBottom: 20
    }
}

const Question = (props) => {
    const [yesStyle, setYesStyle] = useState(styles.button);
    const [noStyle, setNoStyle] = useState(styles.button);

    return (
    <div style={styles.container}>
        <div style={styles.image}><img src={props.image} alt="Question"/></div>
        <span style={styles.question}>{props.question}</span>
        <div style={props.skip ? styles.skipContainer : styles.questionContainer}>
            <button 
            style={yesStyle} 
            onClick={() => {
                setYesStyle({...yesStyle, ...styles.buttonClicked})
                setNoStyle(styles.button)
                props.function(props.questionName, true)
                }}>
                <span style={{textAlign: 'center'}}>Yes</span>
            </button>
            <button 
            style={noStyle}
            onClick={() => {
                setYesStyle(styles.button)
                setNoStyle({...noStyle, ...styles.buttonClicked})
                props.function(props.questionName, false)
                }}>
                <span style={{textAlign: 'center'}}>No</span>
            </button>
        </div>
    </div>
    )
}

const SmallQuestion = (props) => {
    const [yesStyle, setYesStyle] = useState(styles.smallButton);
    const [noStyle, setNoStyle] = useState(styles.smallButton);

    return (
    <div style={{textAlign: 'center'}}>
        <span style={styles.smallQuestion}>{props.question}</span>
        <div style={styles.smallQuestionContainer}>
            <button 
            style={yesStyle} 
            onClick={() => {
                setYesStyle({...yesStyle, ...styles.buttonClicked})
                setNoStyle(styles.smallButton)
                props.function(props.questionName, true)
                }}>
                <span style={{textAlign: 'center'}}>Yes</span>
            </button>
            <button 
            style={noStyle}
            onClick={() => {
                setYesStyle(styles.smallButton)
                setNoStyle({...noStyle, ...styles.buttonClicked})
                props.function(props.questionName, false)
                }}>
                <span style={{textAlign: 'center'}}>No</span>
            </button>
        </div>
    </div>
    )
}

const CheckIn = (props) => {
    const history = useHistory();
    const { userData } = useSelector(state => state.application)

    let { token } = useSelector(state => state.application)
    if (token === null) {token = 'guest'}

    const initialData = {
        hadCloseContact: null,
        travelExposure: null,
        preventable: null,
        persistentCough: null,
        breathShortness: null,
        highTemp: null,
        senseLoss: null,
        otherSymptoms: null,
        tempCheck: null,
    }

    const [data, setData] = useState(initialData)

    const onPress = (question, answer) => {
        switch (question) {
            case ('hadCloseContact'):
                setData({...data, hadCloseContact: answer})
                break;
            case ('travelExposure'):
                setData({...data, travelExposure: answer})
                break;
            case ('preventable'):
                setData({...data, preventable: answer})
                break;
            case ('multipleQuestions'):
                setData({...data, multipleQuestions: answer})
                break;
            case ('persistentCough'):
                setData({...data, persistentCough: answer})
                break;
            case ('breathShortness'):
                setData({...data, breathShortness: answer})
                break;
            case ('highTemp'):
                setData({...data, highTemp: answer})
                break;
            case ('senseLoss'):
                setData({...data, senseLoss: answer})
                break;
            case ('otherSymptoms'):
                setData({...data, otherSymptoms: answer})
                break;
            case ('tempCheck'):
                setData({...data, tempCheck: answer})
                break;
            case ('confirm'):
                console.log(props.location.state)
                let checkInData = {};
                if (props.location.state.isGuest) {
                    checkInData = {
                        station_id:  props.location.state.station_id,
                        name: props.location.state.name,
                        email: props.location.state.email,
                        phone_number: props.location.state.phone_number,
                        employee_id: null,
                        data: data,
                        project_name: props.location.state.project_name  
                    }
                } else {
                    checkInData = {
                        station_id:  props.location.state.station_id || 0,
                        name: userData.legal_name,
                        email: userData.email,
                        phone_number: userData.phone_number,
                        employee_id: userData.id,
                        data: data,
                        project_name: props.location.state.project_name || userData.user_type
                    }
                }

                axios.post(CHECKIN_URL + token, checkInData)
                .then(res => {
                    console.log(res.data)
                    const { isSafe } = res.data
                    
                    history.push({
                        pathname: '/check-in/result/',
                        state: { isSafe: isSafe } 
                    });
                
                })
                .catch(err => {
                    console.warn('error:', err)
                })
                break;

            default:
                setData({...data})
        }
        
    }

    

    return (
        <div>
            <Question
            image={closeContact}
            question={questions.hadCloseContact}
            function={onPress}
            questionName={'hadCloseContact'}
            />
            <Question
            image={travelExposure}
            question={questions.travelExposure}
            function={onPress}
            questionName={'travelExposure'}
            />
            <Question
            image={preventable}
            question={questions.preventable}
            function={onPress}
            questionName={'preventable'}
            />
            <div style={{...styles.image, flexDirection: 'column', textAlign: 'center', marginBottom: 40}}>
                <img src={multipleQuestions} alt="Questions"/>
                <span style={styles.question}>{questions.multipleQuestions}</span>
            </div>
            <SmallQuestion
            question={questions.persistentCough}
            function={onPress}
            questionName={'persistentCough'}
            />
            <SmallQuestion
            question={questions.breathShortness}
            function={onPress}
            questionName={'breathShortness'}
            />
            <SmallQuestion
            question={questions.highTemp}
            function={onPress}
            questionName={'highTemp'}
            />
            <SmallQuestion
            question={questions.senseLoss}
            function={onPress}
            questionName={'senseLoss'}
            />
            <SmallQuestion
            question={questions.otherSymptoms}
            function={onPress}
            questionName={'otherSymptoms'}
            />
            <div style={styles.whitespace}>
                {/* White Space */}
            </div>
            <Question
            image={tempCheck}
            question={questions.tempCheck}
            function={onPress}
            questionName={'tempCheck'}
            skip={true}
            />
            <div style={{...styles.image, flexDirection: 'column', textAlign: 'center', alignItems: 'center'}}>
                <img src={confirm} alt="Confirm"/>
                <span style={styles.question}>{questions.confirm}</span>
                <button 
                style={styles.confirmButton}
                onClick={() => onPress('confirm')}
                >
                    <span style={{...styles.question, marginTop: 0}}>Yes</span>
                </button>
            </div>
        </div>
    )
}

export default CheckIn;