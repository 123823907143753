import { TRANSPORT_CHECKIN, TRANSPORT_CHECKOUT } from '../types.js'

export const initialTransportState = {
    email: null,
    name: null,
    phoneNumber: null,
    checkIn: null,
};

const transportReducer = (state = initialTransportState, action) => {
    switch (action.type) {
        case TRANSPORT_CHECKIN:
            return (
                console.log('(Redux) Transport check-in info.'),
                    {...state,
                        email: action.payload.email,
                        name: action.payload.name,
                        phoneNumber: action.payload.phoneNumber,
                        checkIn: action.payload.checkIn,
                    }
                )
        case TRANSPORT_CHECKOUT:
            return (
                console.log('(Redux) Transport check-out.'),
                    {...state,
                        email: null,
                        name: null,
                        phoneNumber: null,
                        checkIn: null,
                    }
                )
        default:
            return {...state};
    }
};

export default transportReducer;