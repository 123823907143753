import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { GET_STATIONS, ADD_STATION, ADD_EMPLOYEE, SEND_CSV, style, media } from '../../globalVariables.js';
import { useDropzone } from 'react-dropzone';

//Images
import addStation from '../../img/stations/add-stations.svg';
import qrCode from '../../img/stations/qrCode.svg';
import exit from '../../img/stations/exit.svg';
import addEmployee from '../../img/stations/addEmployee.svg';
// import refreshStation from '../../img/stations/refreshStation.svg';
import csvLogo from '../../img/stations/csvLogo.svg';
import positive from '../../img/result-icons/positiveResult.svg';
import negative from '../../img/result-icons/negativeResult.svg';
import error from '../../img/result-icons/error.svg';

//Utilites
import { parseCSV } from '../../utils/parseCSV.js'
import { checkEmployeeCreation } from '../../utils/checkEmployeeCreation.js';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateStations } from '../../redux/actions/updateStations.js';

//Components
import { StatusIcon } from './ManagerScreen.js';
import Menu from './Menu.js';

const Styles = {
    Container: styled.div`
    display: flex;

    .response-message {
        font-weight: bold;
        color: red;
    }

    span {
        ${media.nonSelectable};
    }
    `,
    Body: styled.div`
    width: 80%;
    border-top: solid grey 1px;
    display: flex;
    margin-top: 1%;

    .column-title {
        display: flex;
        justify-content: center;
        border-bottom: solid grey 1px;

        span {
            font-size: 30px;
        }
    }

    .station-title {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-bottom: solid grey 1px;

        span {
            font-size: 30px;
        }

        img {
            max-width: 30px;
            :hover {
                box-shadow: 1px 0px 5px 5px #85b2ff;
            }
        }

    }
    `,
    StationList: styled.div`
    width: 50%;

    .station-title {
        ${media.fonts.header}
    }
    
    .station-container {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-evenly;

        img {
            max-width: 30px;
            background-color: ${style.purple};
            border: solid ${style.purple} 1px;

            :hover {
                border: solid #85b2ff 1px;
                background-color: #85b2ff;
            }
        }
    }
    `,
    Data: styled.div`
    width: 50%;
    border-left: solid grey 1px;

    .column-title {
        ${media.fonts.header}
    }

    .station-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    `,
    StationContainer: styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${style.purple};
    border-radius: 10px;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;

    span {
        ${media.fonts.text}
        font-size: 28px;
        color: white;
        padding: 0px 10px 0px 10px;
    }

    :hover {
        box-shadow: 1px 0px 5px 5px #85b2ff;
    }

    `,
    EntryContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${style.purple};
    border-radius: 10px;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;

    min-height: 30px;

    img {
        max-width: 10%;
    }

    span {
        color: white;
        ${media.fonts.text}
    }
    `,
    AddStationContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid grey 1px;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
    margin-top: 5%;

    .add-title {
        margin-bottom: 10%;
        font-size: 30px;
    }

    img {
        max-width: 50px;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15%;

        span {
            margin-bottom: 5%;
        }

        input {
            font-size: 20px;
            text-align: center;
            margin-top: 2%;
            margin-bottom: 2%;
        }

        .submit-button {
            margin-top: 5%;
            color: white;
            border: none;
            background-color: ${style.purple};
        }
    }
    `,
    DropZone: styled.div`
        border: dashed black 1px;
        height: 150px;
        width: 60%;
        margin-top: 10%;
        margin-bottom: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;

        span {
            font-size: 30px;
            color: black;
        }
    `,
    Confirm: styled.div`

        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            width: 80%;
            display: flex;
            justify-content: space-evenly;
            border: solid #b580e0 1px;
            border-radius: 10px;
            background-color: #b580e0;
            margin: 20px 0px 20px 0px;
            padding: 10px 0px 10px 0px;

            span {
                color: white;
                font-size: 15px;
            }
        }
    `,
    ResultsBody: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .response {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border-radius: 10px;
            margin-bottom: 10%;
            margin-top: 10%;

            span {
                font-size: 30px;
                color: white;
            }

            img {
                width: 10%;
            }
        }

        .inserted-users-container {
            width: 100%;
            display: flex;
            flex-direction: column;

            .inserted-users {
                width: 100%:
                border: solid lightgreen 1px;
                background-color: lightgreen;
            }

            .manager-id-container {
                display: flex;
                flex-direction: column;
                width: 100%;

                .manager-id {
                    border: solid lightgreen 2px;
                    width: 50%;
                    margin-bottom: 2%;
                    margin-top: 2%;

                    span {
                        color: lightgreen;
                        font-size: 20px;
                        padding-top: 10%;
                        padding-bottom: 10%;
                    }
                }
            }
        }
    
    }

        .failed-users-container {
            display: flex;
            flex-direction: column;

            .failed-users {
                width: 100%;
                border: solid #F36363 1px;
                background-color: #F36363;
            }

            .issues {
                display: flex;
                flex-direction: column;
                width: 100%;

                .issue {
                    border: solid #F36363 2px;
                    width: 100%;
                    margin-bottom: 2%;
                    margin-top: 2%;

                    span {
                        color: #F36363;
                        font-size: 15px;
                    }

                    .message {
                        width: 90%;
                        display: flex;
                        justify-content: space-evenly;
                    }
                }
            }
        }            
    }

    button {
        border: solid green 1px;
        border-radius: 10px;
        background-color: green;
        color: white;
        font-size: 20px;
        padding: 10px 20px 10px 20px;
        outline: none;
    }

    button:hover {
        background-color: lightgreen;
        border: solid lightgreen 1px;
    }
`,
}

const Entry = (props) => {
    return (
        <Styles.EntryContainer>
            <span>#{props.id}</span>
            <span>{props.name}</span>
            <span>{props.project_name}</span>
            <StatusIcon selfChecked={
                props.passed ? 'passed' : 'failed'
            }/>
        </Styles.EntryContainer>
    )
}

const defaultEmployeeDetails = {
    legal_name: '',
    email: '',
    phone_number: '',
    DOB: '',
    department: ''
}

const StationDashboard = () => {
    const user = useSelector(state => state.application);
    const dispatch = useDispatch();
    const [data, setData] = useState({id: null, entries: [], addingStation: 0, addEmployee: 0, addingCSV: 0});
    const [csvData, setCSVData] = useState([]);
    const [stationName, setStationName] = useState({stationName: ''});
    const [isLoaded, setIsLoaded] = useState(false)
    const [results, setResults] = useState({insertedUsers: [], failedUsers: []});
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [employeeDetails, setEmployeeDetails] = useState(defaultEmployeeDetails)
    const [responseMessage, setResponseMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false)

    const { token, userData, stationInfo } = user;


    const onDrop = useCallback(acceptedFiles => {

        let reader = new FileReader();
        reader.readAsText(acceptedFiles[0])

        reader.onload = (e) => {
            const csvData = parseCSV(e.target.result, userData.id)
            setCSVData(csvData);
            setIsLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop})

    const onConfirm = () => {
        axios.post(SEND_CSV + token, csvData)
            .then(res => {
                console.log(res.data);
                setResults(res.data.response);
                setIsConfirmed(true);
                setIsLoaded(false);
            })
            .catch(err => {
                console.log(err)
            })
    }




    const showData = (id) => {
        const stationEndpoint = GET_STATIONS + `${id}/${token}`;
        setData({...data, addingStation: 0, addingEmployee: 0, addingCSV: 0})
        setIsConfirmed(false)
        if (id === data.id) {
            //pass
        }
        else {
            axios.get(stationEndpoint)
            .then(res => {
                console.log(res.data.stationEntries)
                if(res.data.isFetched) {
                    setData({...data, id: id, entries: res.data.stationEntries})
                }
            })
            .catch(err => {
                setData({...data, id: null, entries: []})
            })
        }
    }

    const onAddStation = () => {
        setEmployeeDetails(defaultEmployeeDetails);
        setResponseMessage('')
        setData({id: null, entries: [], addingStation: 1, addingEmployee: 0, addingCSV: 0})
    }

    const onAddEmployees = () => {
        setStationName({stationName: ''})
        setResponseMessage('')
        setData({id: null, entries: [], addingStation: 0, addingEmployee: 1, addingCSV: 0})
    }

    const onAddCSV = () => {
        setResponseMessage('')
        setStationName({stationName: ''})
        setEmployeeDetails(defaultEmployeeDetails);
        setIsConfirmed(false)
        setCSVData([]);
        setData({id: null, entries: [], addingStation: 0, addingEmployee: 0, addingCSV: 1})
    }


    const Station = (props) => {

        return (
            <Styles.StationContainer onClick={() => showData(props.id)}>
                <span>#{props.id}</span>
                <span>{props.station_name}</span>
            </Styles.StationContainer>
        )
    }

    const onCreateEmployee = (e) => {
        e.preventDefault();

        setIsSubmitting(true);
        setResponseMessage('Adding employee...')

        const checkedInputs = checkEmployeeCreation(employeeDetails)
        console.log(employeeDetails)
        if(checkedInputs.isValid) {
            axios.post(ADD_EMPLOYEE + token, employeeDetails)
            .then(res => {
                console.log(res.data)
                if (res.data.isInserted) {
                    setResponseMessage('Employee added!')
                    setIsSubmitting(false);
                    setEmployeeDetails(defaultEmployeeDetails)
                } else {
                    setResponseMessage(res.data.message);
                    setIsSubmitting(false);
                    setEmployeeDetails(defaultEmployeeDetails);
                }

            })
            .catch(err => {
                console.log(err);
                setEmployeeDetails(defaultEmployeeDetails);
                setResponseMessage('Encountered a server error. Please try again.');
            })
        } else {
            setEmployeeDetails(defaultEmployeeDetails);
            setResponseMessage(checkedInputs.message);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        
        setIsSubmitting(true)
        if (typeof stationName.stationName === 'string' &&
            stationName.stationName.length <= 38) {
                axios.post(ADD_STATION + token, { name: stationName.stationName })
                .then(res => {
                    if(res.data.isCreated) {
                        setIsSubmitting(false)
                        setResponseMessage(res.data.message)
                        dispatch(updateStations(res.data.currentStations))
                    }
                })
                .catch(err => {
                    setIsSubmitting(false)
                    setStationName('')
                    setResponseMessage('There was an issue. Try again.')
                    console.log(err)
                })
        } else {
            setIsSubmitting(false)
            setStationName('')
            setResponseMessage('Invalid station name.')
        }
    }

    const handleChange = (e) => {
        setStationName({...stationName, stationName: e.target.value})
    }

    const handleEmployeeChange = (e, dataPoint) => {
        switch(dataPoint) {
            case('legal_name'):
                setEmployeeDetails({...employeeDetails, legal_name: e.target.value})
            break;
            case('email'):
                setEmployeeDetails({...employeeDetails, email: e.target.value})
            break;
            case('DOB'):
                setEmployeeDetails({...employeeDetails, DOB: e.target.value})
            break;
            case('phone_number'):
                setEmployeeDetails({...employeeDetails, phone_number: e.target.value})
            break;
            case('department'):
                setEmployeeDetails({...employeeDetails, department: e.target.value})
            break;
            default:
                //pass
        }
    }



    return (
        <Styles.Container>
            <Menu/>
            <Styles.Body>
                <Styles.StationList>
                    <div className="station-title">
                        <span>Stations</span>
                        <img onClick={onAddStation} src={addStation} alt="add station"/>
                        <img onClick={onAddEmployees} src={addEmployee} alt="add employees"/>
                        <img onClick={onAddCSV} src={csvLogo} alt="add employees"/>
                    </div>
                    <div className="group-container">
                        {
                            stationInfo.map((station, key) => {
                                return (
                                <div className="station-container">
                                <Station
                                    key={key}
                                    id={station.id}
                                    station_name={station.station_name}
                                />
                                <Link to={`/qr-code/${station.id}`}>
                                    <img src={qrCode} alt="qr code"/>
                                </Link>
                                </div>
                                )
                            })
                        }
                    </div>
                </Styles.StationList>
                <Styles.Data>
                    <div className="column-title">
                        <span>Data</span>
                    </div>
                    <div className="station-container">
                        {
                            data.entries.map((entry, key) => {
                                return <Entry
                                    key={key}
                                    id={entry.id}
                                    name={entry.name}
                                    project_name={entry.project_name}
                                    passed={entry.passed}
                                />
                            })
                        }
                    </div>
                    <Styles.AddStationContainer style={{display: data.addingStation ? '' : 'none'}}>
                        <span className="add-title">Add a Station</span>
                        <form onSubmit={onSubmit}>
                            <span className="response-message">{responseMessage}</span>
                            <span>Station Name:</span>
                            <input
                            type="text"
                            value={stationName.stationName}
                            onChange={handleChange}
                            />
                            <input
                            className="submit-button"
                            type="submit"
                            value={!isSubmitting ? 'Submit' : 'Submitting..'}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            />
                        </form>
                        <img onClick={() => setData({...data, addingStation: 0})} src={exit} alt="exit window"/>
                    </Styles.AddStationContainer>
                    <Styles.AddStationContainer style={{display: data.addingEmployee ? '' : 'none'}}>
                        <span className="add-title">Add an Employee</span>
                        <form onSubmit={onCreateEmployee}>
                            <span className="response-message">{responseMessage}</span>
                            <span>Name:</span>
                            <input
                            type="text"
                            value={employeeDetails.legal_name}
                            onChange={(e) => handleEmployeeChange(e, 'legal_name')}
                            />
                            <span>Email:</span>                            
                            <input
                            type="text"
                            value={employeeDetails.email}
                            onChange={(e) => handleEmployeeChange(e, 'email')}
                            />
                            <span>Phone Number:</span>
                            <input
                            type="text"
                            value={employeeDetails.phone_number}
                            onChange={(e) => handleEmployeeChange(e, 'phone_number')}
                            />
                            <span>Date of Birth:</span>
                            <input
                            type="text"
                            value={employeeDetails.DOB}
                            placeholder="mm/dd/yyyy"
                            onChange={(e) => handleEmployeeChange(e, 'DOB')}
                            />
                            <span>Department:</span>
                            <input
                            type="text"
                            value={employeeDetails.department}
                            onChange={(e) => handleEmployeeChange(e, 'department')}
                            />
                            <input
                            className="submit-button"
                            type="submit"
                            value={!isSubmitting ? 'Submit' : 'Submitting..'}
                            disabled={isSubmitting}
                            />
                        </form>
                        <img onClick={() => {
                            setEmployeeDetails(defaultEmployeeDetails);
                            setData({...data, addingEmployee: 0})
                        }} src={exit} alt="exit window"/>
                    </Styles.AddStationContainer>
                    <Styles.AddStationContainer style={{display: (data.addingCSV && !isConfirmed) ? '' : 'none'}}>
                        <Styles.DropZone {...getRootProps()}>
                            <input {...getInputProps()}/>
                            <span>Upload your CSV</span>
                        </Styles.DropZone>
                        <div className="header">
                            <span>Confirm loaded data:</span>
                        </div>
                        <Styles.Confirm>
                        <button onClick={onConfirm} disabled={!isLoaded}>
                            CONFIRM
                        </button>
                            {csvData.map(user => {
                                return (
                                    <div className="container">
                                        <span>{user.legal_name || user.name}</span>
                                        <span>{user.department}</span>
                                        <span>{user.email}</span>
                                    </div>
                                )
                            })}
                        </Styles.Confirm>
                    </Styles.AddStationContainer>
                    <Styles.ResultsBody style={{display: isConfirmed ? '' : 'none'}}>
                    <div className="response">
                        <span>Here are your results:</span>
                        <div className="inserted-users-container">
                            <div className="inserted-users">
                                <img src={positive} alt="positive"/>
                                <span>{results.insertedUsers.length} added to database</span>
                            </div>
                            <div className="manager-id-container">
                                {results.insertedUsers.map(user => {
                                    if (user.user_type === 'manager') {
                                        return (
                                            <div className="manager-id">
                                                <span>{user.name} has been assigned ID #{user.id[0]}</span>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <>
                                            </>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        <div className="failed-users-container">
                            <div className="failed-users">
                                <img src={negative} alt="negative"/>
                                <span>{results.failedUsers.length} encountered errors</span>
                            </div>
                            <div className="issues">
                                {results.failedUsers.map(user => {
                                    return (
                                        <div className="issue">
                                            <img src={error} alt="error"/>
                                            <div className="message">
                                                <span>{user.email}</span>
                                                <span>-</span>
                                                <span>{user.issue}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        
                    </div>
                </Styles.ResultsBody>
                </Styles.Data>
            </Styles.Body>
        </Styles.Container>
    )
}

export default StationDashboard;
