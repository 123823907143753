import React from 'react';
import styled from 'styled-components';
import { QR_CODE_URL } from '../globalVariables.js';

//Images
import logo from '../img/logo.jpg';

const QRCode = require('qrcode.react');


const Styles = {
    Container: styled.div`
    display: flex;
    flex-direction: column;

    .code {
        margin: 0 auto;
        zoom: 2;
        margin-bottom: 2%;
    }

    .header {
        font-size: 40px;
        margin: 0 auto;
        margin-bottom: 10%;

    }

    .powered-by {
        font-size: 15px;
        margin: 0 auto;
    }

    .logo {
        max-width: 20%;
        margin: 0 auto;
    }
    `,
}

const QRCodeGenerator = (props) => {

    const { id } = props.match.params;
    return (
        <Styles.Container style={{display: 'flex', justifyContent: 'center'}}>
            <QRCode height={100} width={100} className="code" value={QR_CODE_URL + id}/>
            <span className="header">
                Covid-19 Self Check
            </span>
            <span className="powered-by">
                Powered by:
            </span>
            <img className="logo" src={logo} alt="medical placement association"/>
        </Styles.Container>
    )
}

export default QRCodeGenerator;