import { createStore, combineReducers } from 'redux';

//Reducers
import applicationReducer from './reducers/applicationReducer.js';
import transportReducer from './reducers/transportReducer.js';

const rootReducer = combineReducers({
    application: applicationReducer,
    transport: transportReducer
})

const configureStore = () => createStore(rootReducer);

export default configureStore