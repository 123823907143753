export const CREATE_STATION = 'https://prod-labs-mpa-test.herokuapp.com/stations/create'
export const LOGIN_ENDPOINT = 'https://prod-labs-mpa-test.herokuapp.com/auth/login';
export const TRANSPORT_ENDPOINT = 'https://prod-labs-mpa-test.herokuapp.com/transport/entry';
export const UPDATE_TEAM_INFO = 'https://prod-labs-mpa-test.herokuapp.com/data/';
export const ADD_EMPLOYEE = 'https://prod-labs-mpa-test.herokuapp.com/data/employee/';
export const GET_STATIONS = 'https://prod-labs-mpa-test.herokuapp.com/stations/'
export const ADD_STATION = 'https://prod-labs-mpa-test.herokuapp.com/stations/create/'
export const CHECKIN_URL = 'https://prod-labs-mpa-test.herokuapp.com/check-in/'
export const QR_CODE_URL = 'https://www.productionlaboratories.com/check-in/auth/'
export const SEND_CSV = `https://prod-labs-mpa-test.herokuapp.com/data/admin/`;
export const FETCH_TEAM = 'https://prod-labs-mpa-test.herokuapp.com/data/team/'
export const FETCH_PRODUCTIONS = 'https://prod-labs-mpa-test.herokuapp.com/admin/productions/'
export const ADD_MANAGER = 'https://prod-labs-mpa-test.herokuapp.com/admin/managers/'
export const FETCH_MANAGERS = 'https://prod-labs-mpa-test.herokuapp.com/admin/managers/';



// export const CREATE_STATION = 'http://localhost:4201/stations/create'
// export const LOGIN_ENDPOINT = 'http://localhost:4201/auth/login';
// export const TRANSPORT_ENDPOINT = 'http://localhost:4201/transport/entry';
// export const UPDATE_TEAM_INFO = 'http://localhost:4201/data/';
// export const ADD_EMPLOYEE = 'http://localhost:4201/data/employee/';
// export const GET_STATIONS = 'http://localhost:4201/stations/'
// export const ADD_STATION = 'http://localhost:4201/stations/create/'
// export const CHECKIN_URL = 'http://localhost:4201/check-in/'
// export const QR_CODE_URL = 'http://localhost:3000/check-in/auth/'
// export const SEND_CSV = `http://localhost:4201/data/admin/`;
// export const FETCH_TEAM = 'http://localhost:4201/data/team/'
// export const FETCH_PRODUCTIONS = 'http://localhost:4201/admin/productions/';
// export const ADD_MANAGER = 'http://localhost:4201/admin/managers/'
// export const FETCH_MANAGERS = 'http://localhost:4201/admin/managers/'


export const media = {
    screen: {
        desktop: '@media (min-width: 768px)',
        mobile: '@media (max-width: 768px)',
        tablet: null
    },
    fonts: {
        header: "font-family: 'Roboto Condensed', sans-serif;",
        text: "font-family: 'Open Sans', sans-serif;"
    },
    nonSelectable: `
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    `
}

export const style = {
    purple: '#b580e0',
    grey: '#EDEDED'
}

export const version = 'Alpha v1.3.2'