export function checkLoginInputs(inputs) {

    if(inputs.project_name) {
        if (!inputs.email.includes('@' && '.')) {
            return {isValid: false, message: 'Please provide a valid email.', issue: 'email'}
        }
    
        else if (inputs.password === '') {
            return {isValid: false, message: 'Enter password.', issue: 'password'}
        }

        else if (inputs.project_name === 'Select Project') {
            return {isValid: false, message: 'Please select your project', issue: 'project_name'}

        }
        else return {isValid: true, message: null, issue: null}

    } else {
        if (!inputs.email.includes('@' && '.')) {
            return {isValid: false, message: 'Please provide a valid email.', issue: 'email'}
        }
    
        else if (inputs.password === '') {
            return {isValid: false, message: 'Enter password.', issue: 'password'}
        }
        else return {isValid: true, message: null, issue: null}
    }
    
    

}

export function checkGuestInputs(inputs) {
    console.log(inputs)

    if (inputs.project_name) {
        if (!inputs.guestEmail.includes('@' && '.')) {
            return {isValid: false, message: 'Please provide a valid email.', issue: 'email'}
        }
    
        else if (inputs.name === '') {
            return {isValid: false, message: 'Enter your name.', issue: 'name'}
        }
    
        else if (inputs.phoneNumber === '') {
            return {isValid: false, message: 'Enter your phone number.', issue: 'phoneNumber'}
        }
    
        else if (inputs.phoneNumber.length >= 11) {
            return {isValid: false, message: 'Enter a valid phone number.', issue: 'phoneNumber'}
        }

        else if (inputs.project_name === 'Select Project') {
            return {isValid: false, message: 'Please select your project', issue: 'project_name'}
        }

        else return {isValid: true, message: null, issue: null}


    } else {
        if (!inputs.guestEmail.includes('@' && '.')) {
            return {isValid: false, message: 'Please provide a valid email.', issue: 'email'}
        }
    
        else if (inputs.name === '') {
            return {isValid: false, message: 'Enter your name.', issue: 'name'}
        }
    
        else if (inputs.phoneNumber === '') {
            return {isValid: false, message: 'Enter your phone number.', issue: 'phoneNumber'}
        }
    
        else if (inputs.phoneNumber.length >= 11) {
            return {isValid: false, message: 'Enter a valid phone number.', issue: 'phoneNumber'}
        }
    
        else return {isValid: true, message: null, issue: null}
    }
}