import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media, FETCH_TEAM } from '../../globalVariables.js';
import axios from 'axios';

//Images
import backArrow from '../../img/employee/back-arrow.svg';

//Redux
import { useSelector } from 'react-redux'

//Components
import Menu from './Menu.js'
import IndividualEmployee from './IndividualEmployee.js';

const Styles = {
    Container: styled.div`
    display: flex;
    `,
    Body: styled.div`
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    `,
    Status: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: dashed lightgrey 1px;
    width: 30%;

    span {
        ${media.nonSelectable};
    }

    .header {
        width: 100%;
        text-align: center;
        font-size: 20px;
        border-bottom: dashed lightgrey 1px;
        ${media.fonts.header}
    }

    .passed {
        background-color: #58D944;

        :hover {
            background-color: #81DC72;
            border: solid white 1px;
            span {
                color: grey;
            }
        }
    }

    .not-complete {
        background-color: #FBF76E;

        :hover {
            background-color: #FDFA9F;
            border: solid white 1px;
            span {
                color: grey;
            }
        }
    }

    .failed {
        background-color: #FB7979;

        :hover {
            background-color: #FD9F9F;
            border: solid white 1px;
            span {
                color: grey;
            }
        }
    }

    `,
    TeamMember: styled.div`
    border: solid grey 1px;
    border-radius: 10px;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;
    
    span {
        ${media.nonSelectable};
        ${media.fonts.text}
        font-size: 20px
    }

    :hover {
        background-color: grey;
        span {
            color: white;
        }
    }
    `,
    Back: styled.div`
    margin-top: 10%;
    display: flex;
    justify-content: center;
    `
}

const MyTeam = () => {
    const [currentMember, setCurrentMember] = useState({self_checked: null});
    const { token } = useSelector(state => state.application);
    const [teamInfo, setTeam] = useState([])

    useEffect(() => {
        axios.get(FETCH_TEAM + token)
        .then(result => {
            setTeam(result.data);
        })
        .catch(err => {
            console.log(err);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //Filtered arrays
    const completedTeam = teamInfo.filter(member => member.self_checked === 'passed');
    const notCompletedTeam = teamInfo.filter(member => member.self_checked === 'not complete');
    const failedTeam = teamInfo.filter(member => member.self_checked === 'failed');

    const onClick = (member) => {
        setCurrentMember(member);
    }

    const goBack = () => {
        setCurrentMember({self_checked: null})
    }

    return (
        <Styles.Container>
            <Menu/>
            <Styles.Body>
                <Styles.Status>
                    <span className="header">Completed</span>
                    {completedTeam.map((member, key) => {
                        return (
                        <Styles.TeamMember
                        className="passed"
                        onClick={() => onClick(member)}
                        key={key}
                        style={currentMember.self_checked === 'passed' ?
                            {display: 'none'} : {}
                        }
                        >
                            <span>{member.legal_name}</span>
                            <span>-</span>
                            <span>{member.id}</span>
                        </Styles.TeamMember>
                        )
                    })}
                    <IndividualEmployee
                        member={currentMember}
                        display={currentMember.self_checked === 'passed'}
                    />
                    <Styles.Back
                    style={currentMember.self_checked === 'passed' ?
                    {} : {display: 'none'}
                    }>
                        <div onClick={goBack}>
                            <img src={backArrow} alt="go back"/>
                        </div>
                    </Styles.Back>
                </Styles.Status>
                <Styles.Status>
                    <span className="header">Not Completed</span>
                    {notCompletedTeam.map((member, key) => {
                        return (
                        <Styles.TeamMember
                        className="not-complete"
                        onClick={() => onClick(member)}
                        key={key}
                        style={currentMember.self_checked === 'not complete' ?
                            {display: 'none'} : {}
                        }
                        >
                            <span>{member.legal_name}</span>
                            <span>-</span>
                            <span>{member.id}</span>
                        </Styles.TeamMember>
                        )
                    })}
                    <IndividualEmployee
                        member={currentMember}
                        display={currentMember.self_checked === 'not complete'}
                    />
                    <Styles.Back
                    style={currentMember.self_checked === 'not complete' ?
                    {} : {display: 'none'}
                    }>
                        <div onClick={goBack}>
                            <img src={backArrow} alt="go back"/>
                        </div>
                    </Styles.Back>
                </Styles.Status>
                <Styles.Status>
                    <span className="header">Failed</span>
                    {failedTeam.map((member, key) => {
                        return (
                        <Styles.TeamMember
                        className="failed"
                        onClick={() => onClick(member)}
                        key={key}
                        style={currentMember.self_checked === 'failed' ?
                        {display: 'none'} : {} 
                        }
                        >
                            <span>{member.legal_name}</span>
                            <span>-</span>
                            <span>{member.id}</span>
                        </Styles.TeamMember>
                        )
                    })}
                    <IndividualEmployee
                        member={currentMember}
                        display={currentMember.self_checked === 'failed'}
                    />
                    <Styles.Back
                    style={currentMember.self_checked === 'failed' ?
                    {} : {display: 'none'}
                    }>
                        <div onClick={goBack}>
                            <img src={backArrow} alt="go back"/>
                        </div>
                    </Styles.Back>
                </Styles.Status>
            </Styles.Body>
        </Styles.Container>
    )
}

export default MyTeam;
