import React, { useState } from 'react';
import styled from 'styled-components';
import { media, FETCH_PRODUCTIONS } from '../../globalVariables.js';
import axios from 'axios';

//Redux
import { useSelector } from 'react-redux';

//Components
import Menu from './Menu.js';

//Images
import backArrow from '../../img/employee/back-arrow.svg';
import addButton from '../../img/stations/add-stations.svg';

const Styles = {
    Container: styled.div`
    display: flex;

    span {
        ${media.nonSelectable};
    }
    `,
    Body: styled.div`
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .add-button {
        width: 60px;
        position: fixed;
        left: 250px;
    }
    `,
    ShowcaseBody: styled.div`
    width: 80%;
    display: flex;
    `,
    Company: styled.div`
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    border: solid lightgrey 1px;
    border-radius: 10px;
    box-shadow: 5px 10px #ECEBEB;
    margin-top: 4%;
    margin-bottom: 4%;

    .title {
        font-size: 30px;
        margin-top: 2%;
    }

    .id-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2%;
    }

    .id-title {
        font-size: 15px;
    }

    .id {
        font-size: 15px;
    }

    :hover {
        border: solid black 1px;
        background-color: #F6F5F5;
    }
    `,
    CompanyShowcase: styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        font-size: 35px;
        margin-bottom: 2%;
    }

    `,
    CompanyInfo: styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        font-size: 35px;
        margin-bottom: 2%;
    }
    `,
    Production: styled.div`
    width: 70%;
    border: solid lightgrey 1px;
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
    margin-bottom: 4%;
    box-shadow: 5px 5px #ECEBEB;

    span {
        font-size: 20px;
    }
    `,
    Form: styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid lightgrey 1px;
    width: 60%;

    .header {
        font-size: 35px;
    }

    img {
        width: 10%;
    }
    
    .input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10%;
        margin-bottom: 10%;

        input {
            font-size: 35px;
            margin-top: 2%;
        }

        span {
           font-size: 30px; 
        }

        .sub-text {
            font-style: italic;
            font-size: 10px;
        }
    }

    .submit {
        width: 33%;
        height: 30px;
    }
    `
}

const defaultCompanyValues = {
    name: null,
    manager: null
}

const Companies = () => {
    const { companies, token } = useSelector(state => state.application);
    const [companyData, setCompanyData] = useState({isShowcasing: false, company: {}, data: {}})
    const [isAdding, setIsAdding] = useState(false);
    const [newCompany, setNewCompany] = useState(defaultCompanyValues)

    const showcaseCompany = (index) => {
        setIsAdding(false)
        axios.post(FETCH_PRODUCTIONS + token, {companyID: companies[index].id})
        .then(response => {
            console.log(response.data)
            setCompanyData({
                isShowcasing: true,
                company: companies[index],
                data: response.data.productions
            })
        })
        .catch(err => {
            console.log('err', err)
        })
        
    }

    const onClose = (type) => {
        if (type === 'company data') {
            setCompanyData({
                isShowcasing: false,
                company: {},
                data: {}
            })
        } else {
            setIsAdding(false)
            setNewCompany(defaultCompanyValues)
        }
        
    }

    const onAddCompany = () => {
        setCompanyData({
            isShowcasing: false,
            company: {},
            data: {}
        })
        setIsAdding(true)
    }

    const onChange = (value, datapoint) => {
        if(datapoint === 'name') {
            setNewCompany({
                ...newCompany,
                name: value
            })
        } else {
            setNewCompany({
                ...newCompany,
                manager: value
            })
        }
   }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    if (companyData.isShowcasing) {
        return (
            <Styles.Container>
                <Menu/>
                <Styles.ShowcaseBody>
                    <Styles.CompanyShowcase>
                        <img src={backArrow} onClick={() => onClose('company data')} alt="go back"/>
                        <span className="header">{companyData.company.name}</span>
                    </Styles.CompanyShowcase>
                    <Styles.CompanyInfo>
                        <span className="header">Productions:</span>
                        {companyData.data.map((production, index) => {
                            return (
                            <Styles.Production>
                                <span>{production.id}</span>
                                <span>{production.name}</span>
                            </Styles.Production>
                            )
                        })}
                    </Styles.CompanyInfo>
                </Styles.ShowcaseBody>
            </Styles.Container>
        )
    } else if (isAdding) {
        return (
            <Styles.Container>
                <Menu/>
                <Styles.Body>
                    <Styles.Form onSubmit={onSubmit}>
                        <span className="header">Add a Company</span>
                        <img src={backArrow} onClick={() => onClose('adding company')} alt="go back"/>
                        <div className="input-container">
                            <span>Company Name:</span>
                            <input
                                type="text"
                                value={newCompany.name}
                                onChange={(e) => onChange(e.target.value, 'name')}
                            />
                        </div>
                        <div className="input-container">
                            <span>Manager Email:</span>
                            <span className="sub-text">(manager must already be created)</span>
                            <input
                                type="text"
                                value={newCompany.manager}
                                onChange={(e) => onChange(e.target.value, 'manager')}
                            />
                        </div> 
                        <input
                            className="submit"
                            type="submit"
                            value="SUBMIT"
                        />
                    </Styles.Form>
                </Styles.Body>
            </Styles.Container>
        )
    } else {
        return (
            <Styles.Container>
                <Menu/>
                <Styles.Body>
                    <img className="add-button" onClick={onAddCompany} src={addButton} alt="add company"/>
                    {companies.map((company, index) => {
                        return (
                            <Styles.Company
                                key={index}
                                onClick={() => showcaseCompany(index)}
                            >
                                <span className="title">{company.name}</span>
                                <div className="id-container">
                                    <span className="id-title">Company ID:</span>
                                    <span className="id">{company.id}</span>
                                </div>
                            </Styles.Company>
                        )
                    })}
                </Styles.Body>
            </Styles.Container>
        )
    }
}

export default Companies;