import { LOGOUT } from '../types.js'

export const logout = () => {
    return {
        type: LOGOUT,
        payload: {
            token: null,
            userData: {
                user_type: null,
            },
            teamInfo: [],
            stationInfo: []
        }
    }
}
