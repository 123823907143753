import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { checkLoginInputs } from '../utils/checkInputs.js';
import axios from 'axios';
import styled from 'styled-components';
import { media, LOGIN_ENDPOINT } from '../globalVariables.js';

//Styled Components
import { FormContainer } from '../Components/loginStyles.js';

//Images
import logo from '../img/logo.jpg';
import usernameIcon from '../img/auth/username.svg';
import passwordIcon from '../img/auth/password.svg';

//Redux
import { login } from '../redux/actions/login.js';
import { useDispatch } from 'react-redux';


const defaultInputs = {
    email: '',
    password: ''
};


const Styles = {
    Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    background-image: linear-gradient(to bottom right, #F601FE, #01DCFE);
    overflow: hidden;
    `,
    AuthContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 35%;
    height: 80%;
    border: none;
    border-radius: 10px;
    margin-top: 75px;
    background-color: white;
    position: relative;
    z-index: 2;

    .header {
        margin: 0 auto;
        font-size: 30px;
        font-weight: bold;
        margin-top: 5%;
        ${media.fonts.header}
    }

    ${media.screen.mobile} {
        width: 90vw;
    }
    `,
    Input: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    margin-right: 20%;

    .label {
        margin-bottom: 5%;
        ${media.fonts.text}
    }

    .input-container {
        display: flex;
        border-bottom: solid lightgrey 1px;

        img {
            width: 20px;
            margin-right: 4%;
        }

        input {
            outline: none;
            width: 100%;
            border: none;
            ${media.fonts.text}
        }
        padding-bottom: 4%;
    }

    
    
    `,
    link: {
        textDecoration: 'none',
        textAlign: 'center',
        fontSize: 25,
        width: '60%',
        border: 'solid #b580e0 1px',
        borderRadius: '10px',
        backgroundColor: '#b580e0',
        color: 'white',
        paddingTop: 10,
        paddingBottom: 10
    },
    LogoContainer: styled.div`
        width: 50%;
        margin: 0 auto;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            ${media.fonts.text}
        }

        ${media.screen.desktop} {
            img {
                max-width: 50%;
            }
        }


    .fade-in {
        animation: fadeIn ease 10s;
        -webkit-animation: fadeIn ease 10s;
        -moz-animation: fadeIn ease 10s;
        -o-animation: fadeIn ease 10s;
        -ms-animation: fadeIn ease 10s;
        }

        @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-moz-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-webkit-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-o-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-ms-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }

        ${media.screen.mobile} {
            img {
                width: 60%;
            }
        }
    `,
    logoText: {
    },
    logo: {
        width: '100%'
    },
    Button: styled.input`
        width: 45%;
        height: 40px;
        -webkit-appearance: none;
        font-size: 25px;
        border-radius: 10px;
        border: none;
        background: linear-gradient(to bottom right, #A44AFE, #5393FE);
        color: white;
        padding: 5px 7px 5px 7px;
        margin-top: 20px;
        margin: 0 auto;
        ${media.fonts.header}

        @keyframes bubbleText {
            0% {font-size: 25px;}
            50% {font-size: 20px;}
            100% {font-size: 25px;}
        }

        :hover {
            min-width: 45%;
            animation: bubbleText 1s;
            box-shadow: 1px 0px 5px 5px #85b2ff;
        }
    `,
    Response: styled.div`
        text-align: center;
        span {
            font-weight: bold;
            color: red;
            font-size: 25px;
        }
    `,
    QRCode: styled.div`
    display: flex;
    margin-top: 4%;
    justify-content: center;
    `,
    ImageUnderlayOne: styled.div`
    border-radius: 50px;
    background-color: #af00b5;
    width: 300px;
    height: 300px;
    position: absolute;
    top: 50px;
    left: -80px;
    z-index: 1;
    transform: rotate(20deg);
    opacity: .3;
    overflow: hidden;

    @keyframes bounceOne {
        0% {top: 50px; transform: rotate(20deg);}
        50% {top: 100px; transform: rotate(-130deg);}
        100% {top: 50px; transform: rotate(20deg);}
    }

    animation: bounceOne linear 80s infinite;
    -webkit-animation: bounceOne linear 80s infinite;
    -moz-animation: bounceOne linear 80s infinite;
    -o-animation: bounceOne linear 80s infinite;
    -ms-animation: bounceOne linear 80s infinite;
    `,
    ImageUnderlayTwo: styled.div`
    border-radius: 50px;
    background-color: #2b8a99;
    width: 300px;
    height: 300px;
    position: absolute;
    right: 175px;
    bottom: 70px;
    z-index: 1;
    transform: rotate(-40deg);
    opacity: .3;
    overflow: hidden;



    @keyframes bounceTwo {
        0% {bottom: 68px; transform: rotate(-40deg);}
        50% {bottom: 40px; transform: rotate(180deg);}
        100% {bottom: 68px; transform: rotate(-40deg);}
    }

    animation: bounceTwo linear 65s infinite;
    -webkit-animation: bounceTwo linear 65s infinite;
    -moz-animation: bounceTwo linear 65s infinite;
    -o-animation: bounceTwo linear 65s infinite;
    -ms-animation: bounceTwo linear 65s infinite;
    `,
}

const Login = () => {
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState(defaultInputs);
    const [response, setResponse] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const history = useHistory();

    const onChange = (input, value) => {
        if (input === 'email') {
            setInputs({...inputs, email: value.toLowerCase()})
        } else {setInputs({...inputs, password: value})}
    }

    const onSubmit = (e) => {

        e.preventDefault();
        setIsSubmitting(true);

        const { isValid } = checkLoginInputs(inputs);
        
        if (isValid) {
            axios.post(LOGIN_ENDPOINT, inputs)
            .then(res => {
                console.log(res)
                const { isAuth, token, userData, teamInfo, stations, companies, managers, isFetched, production } = res.data;
                if(isAuth) {

                    const { user } = userData;
                    dispatch(login({
                        token: token,
                        userData: user,
                        teamInfo: teamInfo,
                        stationInfo: stations,
                        companies: companies,
                        managers: managers,
                        isFetched: isFetched,
                        production: production
                    }))

                    history.push({
                        pathname: '/',
                        state: { token: token }
                    });
                } else {
                    setResponse(res.data.response.message)
                    setIsSubmitting(false);

                    setInputs({
                        ...inputs,
                        password: ''
                    })
                }
            })
            .catch(err => {
                console.log('Error:', err)
                
                setResponse('Oops! We had an error on our end. Try again.')
                setIsSubmitting(false);

                setInputs({
                    ...inputs,
                    password: ''
                })
            })
        } else {
            setIsSubmitting(false);

            setResponse('Please enter a valid email or password')

            setInputs({
                ...inputs,
                password: ''
            })
        }
    }

    return (
    <Styles.Container>
        <Styles.ImageUnderlayOne/>
        <Styles.ImageUnderlayTwo/>
        <Styles.AuthContainer>
            <span className="header">
                Login:
            </span>
            <Styles.Response>
                <span>
                    {response}
                </span>
            </Styles.Response>
            <FormContainer onSubmit={onSubmit}>
                <Styles.Input>
                    <span className="label">Email</span>
                    <div className="input-container">
                        <img src={usernameIcon} alt="username"/>
                        <input
                            type="text"
                            value={inputs.email}
                            placeholder={'Type your email'}
                            onChange={(e) => onChange('email', e.target.value)}
                        />
                    </div> 
                </Styles.Input>
                <Styles.Input>
                    <span className="label">Password</span>
                    <div className="input-container">
                        <img src={passwordIcon} alt="password"/>
                        <input
                            type="password"
                            value={inputs.password}
                            placeholder={'Type your password'}
                            onChange={(e) => onChange('password', e.target.value)}
                        />
                    </div>
                    
                </Styles.Input>
                <Styles.Button
                    type="submit"
                    value={!isSubmitting ? 'LOGIN' : 'ONE MOMENT..'}
                    disabled={isSubmitting}
                />
                <Styles.LogoContainer>
                    <span className="fade-in" style={Styles.logoText}>Powered by:</span>
                    <img className="logo fade-in" src={logo} alt="MPA"/>
                </Styles.LogoContainer>
            </FormContainer>
        </Styles.AuthContainer>
   </Styles.Container>
    )
}

export default Login;
