import { UPDATE_STATIONS } from '../types.js';

export const updateStations = (values) => {
    return {
        type: UPDATE_STATIONS,
        payload: {
            stationInfo: values
        }
    }
}
