import { REHYDRATE } from '../types.js'

export const rehydrate = (values) => {
    return {
        type: REHYDRATE,
        payload: {
            rehydration: values
        }
    }
}
