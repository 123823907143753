import styled from 'styled-components';
import { media } from '../globalVariables.js';

export const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100vh;
margin: 0 auto;
`
export const Header = styled.span`
font-size: 50px;
text-align: center;
`
export const SubHeader = styled.span`
font-size: 20px;
text-align: center;
`
export const AuthContainer = styled.div`
display: flex;
flex-direction: column;
margin: 0 auto;
width: 75%;
height: 50%;
border: solid grey 1px;
border-radius: 10px;
margin-top: 75px;

    ${media.screen.desktop} {
        width: 44%;
        margin-top: 15px;
    }
`
export const AuthHeader = styled.span`
text-align: center;
margin-top: 12px;

    ${media.screen.desktop} {
        margin-bottom: 15px;
    }
`
export const FormContainer = styled.form`
display: flex;
flex-direction: column;
justify-content: space-evenly;
height: 100%;
`
export const Input = styled.div`
display: flex;
flex-direction: column;

input {
    text-align: center;
    width: 75%;
}

    ${media.screen.desktop} {
        input {
            font-size: 20px;
        }
    }
`

export const FormText = styled.span`
text-align: center;
font-size: 15px;
margin-top: 20px;
margin-bottom: 10px;
`