import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';


//Components
import Menu from './Menu.js';
import FloatingSquares from '../UtilComponents/FloatingSquares.js';

//Utilites
import { media, style } from '../../globalVariables.js'

//Icons
import badIcon from '../../img/homepage/Manager/badIcon.svg';
import goodIcon from '../../img/homepage/Manager/goodIcon.svg';
import warningIcon from '../../img/homepage/Manager/warningIcon.svg';


export const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    headerText: {
        fontSize: 32
    },
    statBox: {
        textAlign: 'center',
        borderWidth: 1,
        borderRadius: 10,
        width: 80,
        height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    statText: {
        fontSize: 10,
        paddingBottom: 20,
    },
    userBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        border: 'solid grey 1px',
        borderWidth: 1,
        width: '90%',
        marginTop: 30,
        borderRadius: 10,
    },
    itemContainer: {
        marginTop: 20,
        marginBottom: 20
    },
    refresh : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
        marginTop: 20
    },
    refreshButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 120,
        borderRadius: 20
    }
};

const Styles = {
    Container: styled.div`
    display: flex;
    background: linear-gradient(to bottom right, #8D5FFE, #6A7EFE);
    position: relative;
    z-index: 2;
    overflow: hidden;
    `,
    Body: styled.div`
    width: 80%;
    display: flex;
    justify-content: space-evenly;

    span {
        ${media.nonSelectable};
        margin-top: 10%;
        margin-bottom: 5%;
        font-size: 20px;
    }

    p {
        ${media.fonts.text}
        ${media.nonSelectable};
        margin-top: 10%;
        margin-bottom: 5%;
        font-size: 20px;
    }

    `,
    InfoCard: styled.div`
    background-color: ${style.grey};

    span {
        color: grey;
        ${media.fonts.text}
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    height: 50%;
    width: 33%;
    margin-top: 2%;

    .name {
        font-size: 25px;
        margin-top: 0%;
    }

    .id {
        font-size: 10px;
        margin-top: 0%;
        margin-bottom: 0%;
    }

    .production {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: 20px;
        }

        span {
            font-size: 15px;
        }
    }
    `,
    Message: styled.div`
    width: 60%;
    height: 400px;
    margin-top: 2%;
    border-radius: 20px;
    background-color: ${style.grey};

    span {
        color: grey;
        font-size: 35px;
        margin-bottom: 0%;
        margin-top: 0%;
        margin-left: 2%;
    }

    p {
        color: grey;
        font-size: 20px;
        margin-left: 2%;
    }

    .header {
        ${media.fonts.header}
        display: flex;
        flex-direction: column;
        border-radius: 10px;

        .title {
            font-size: 40px;
        }

        .url {
            font-size: 28px;
        }
    }
    `,
}

export const StatusIcon = (props) => {
    const { selfChecked } = props;

    switch (selfChecked) {
        case 'passed' || 1:
            return <img src={goodIcon} alt="passed"/>
        case 'not complete' || 0:
            return <img src={warningIcon} alt="not complete"/>
        case 'failed':
            return <img src={badIcon} alt="failed"/>
        default:
            return <img src={badIcon} alt="failed"/>
    }
}

const ManagerScreen = (props) => {
    const { teamInfo, stationInfo, userData, production } = useSelector(state => state.application)
    return (
        <Styles.Container>
            <Menu/>
            <Styles.Body>
                <Styles.Message>
                    <div className="header">
                        <span className="title">Welcome to</span>
                        <span className="url">Production Laboratories</span>
                    </div>
                    <p>
                        To create stations and/or employees see "My Stations".
                    </p>
                    <p>
                        To monitor your employees see "My Team".
                    </p>
                </Styles.Message>
                <Styles.InfoCard>
                    <span className="name">{userData.name}</span>
                    <span className="id">ID #{userData.id}</span>
                    <span className="stations"># of Stations: {stationInfo.length} </span>
                    <span className="employees"># of Employees: {teamInfo.length}</span>
                    <div className="production">
                        <span className="title">Production:</span>
                        <span>{production[0].name || null}</span>
                    </div>
                </Styles.InfoCard>
            </Styles.Body>
            <FloatingSquares/>
        </Styles.Container>
    )
}

export default ManagerScreen;