import React from 'react';
import styled from 'styled-components';
import { media, style } from '../../globalVariables.js';

//Redux
import { useSelector } from 'react-redux';

//Components
import Menu from './Menu.js';
import FloatingSquares from '../UtilComponents/FloatingSquares.js';

const Styles = {
    Container: styled.div`
    display: flex;
    background: linear-gradient(to bottom right, #8D5FFE, #6A7EFE);
    position: relative;
    z-index: 2;
    overflow: hidden;
    `,
    Body: styled.div`
    width: 80%;
    display: flex;
    justify-content: space-evenly;

    span {
        ${media.nonSelectable};
        margin-top: 10%;
        margin-bottom: 5%;
        font-size: 20px;
    }

    p {
        ${media.nonSelectable};
        margin-top: 10%;
        margin-bottom: 5%;
        font-size: 20px;
    }

    `,
    InfoCard: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid lightgrey 1px;
    border-radius: 10px;
    height: 50%;
    width: 33%;
    background-color: ${style.grey};
    margin-top: 2%;

    

    .id {
        font-size: 10px;
        margin-top: 0%;
        margin-bottom: 0%;
    }

    .name {
        font-size: 25px;
        margin-bottom: 0%;
    }
    `,
    Message: styled.div`
    width: 60%;
    height: 400px;
    margin-top: 2%;
    border-radius: 20px;
    background-color: ${style.grey};

    span {
        font-size: 35px;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    p {
        font-size: 30px;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .url {
        font-size: 20px;
    }
    `
}

const AdminHome = () => {

    const { userData, companies } = useSelector(state => state.application)

    return (
        <Styles.Container>
            <Menu/>
            <Styles.Body>
                <Styles.Message>
                    <div className="header">
                        <span>Welcome to</span>
                        <span className="url">productionlaboratories.com</span>
                    </div>
                    <p>
                        To monitor/create Companies see "Companies"
                    </p>
                </Styles.Message>
                <Styles.InfoCard>
                    <span className="name">{userData.first + " " + userData.last}</span>
                    <span className="id">ID #{userData.id}</span>
                    <span># of Companies: {companies.length}</span>
                </Styles.InfoCard>
            </Styles.Body>
            <FloatingSquares/>
        </Styles.Container>
    )
}

export default AdminHome;