import React from 'react';
import styled from 'styled-components';

const Styles = {
    Container: styled.div`
    position: relative;
    z-index: -1;

    div {
        width: 30px;
        height: 30px;
        border-radius: 10px;
        opacity: .2;
    }

    .one {
        width: 300px;
        height: 300px;

        @keyframes one {
            0% {}
            100% {transform: rotate(360deg);}
        }


        background-color: #F601FE;
        position: absolute;
        z-index: -1;
        right: 31px;
        bottom: 123px;
        animation: one 98.36s linear infinite;
    }
    .two {
        width: 122px;
        height: 122px;

        @keyframes two {
            0% {}
            100% {transform: rotate(360deg);}
        }

        background-color: #01DCFE;
        position: absolute;
        z-index: -1;
        right: 843px;
        bottom: 47px;
        animation: two 40s linear infinite;
    }
    .three {
        width: 98px;
        height: 98px;

        @keyframes three {
            0% {}
            100% {transform: rotate(360deg);}
        }

        background-color: #F601FE;
        position: absolute;
        z-index: -1;
        right: 343px;
        bottom: 431px;
        animation: three 32.13s linear infinite;
    }
    `
}

const FloatingSquares = () => {
    return (
        <Styles.Container>
            <div className="one"/>
            <div className="two"/>
            <div className="three"/>
            <div className="four"/>
            <div className="five"/>
            <div className="six"/>
        </Styles.Container>
    )
}

export default FloatingSquares;
