import { LOGIN } from '../types.js'

export const login = (values) => {
    const { token, userData, teamInfo, stationInfo, companies, managers, isFetched, production } = values;

    return {
        type: LOGIN,
        payload: {
            token: token,
            userData: userData,
            teamInfo: teamInfo,
            stationInfo: stationInfo,
            companies: companies,
            managers: managers,
            isFetched: isFetched,
            production: production
        }
    }
}
