import React from 'react';
import { useHistory } from 'react-router-dom';

//Icons
import positiveResult from '../../img/result-icons/positiveResult.svg';
import negativeResult from '../../img/result-icons/negativeResult.svg';

//Redux
import { logout } from '../../redux/actions/logout.js';
import { useDispatch } from 'react-redux';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh'
    },
    image: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 150
    },
    message: {
        fontSize: 35,
        textAlign: 'center',
        color: 'white'
    },
    nextCheck: {
        fontSize: 25,
        textAlign: 'center'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: 300,
        paddingTop: 50
    },
    button: {
        width: "80%",
        padding: 20,
        border: 'solid white 1px',
        borderRadius: 10,
        marginTop: 20,
        marginBottom: 50,
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        fontSize: 30,
        textDecoration: 'none'
    },
    downloadHeaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    downloadHeader: {
        fontSize: 30,
        textAlign: 'center',
        marginBottom: 10

    },
    downloadSubHeader: {
        fontSize: 20,
        marginBottom: 20
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
}

const initialData = {
    isSafe: false,
    nextCheck: Date.now()
}

const CheckInResult = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { isSafe } = props.location.state || initialData;

    const onClick = () => {
        dispatch(logout());
        history.push('/')
    }

    
    if (isSafe) {
        return (
            <div style={{...styles.container, backgroundColor: '#70c041'}}>
                <div style={styles.image}>
                    <img src={positiveResult} alt="Positive result"/>
                </div>
                <span style={styles.message}>Show screen to supervisor.</span>
                <span style={{...styles.message, fontSize: 25}}>Go to work as scheduled.</span>
                <div style={styles.buttonContainer} onClick={onClick}>
                    <span style={styles.button}>Got It</span>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{...styles.container, backgroundColor: 'red'}}>
                <div style={styles.image}>
                    <img src={negativeResult} alt="Negative result"/>
                </div>
                <span style={styles.message}>Show screen to supervisor.</span>
                <span style={{...styles.message, fontSize: 25}}>Do not got to work as scheduled</span>
                <div style={styles.buttonContainer} onClick={onClick}>
                    <span style={styles.button}>Got It</span>
                </div>
                
            </div>
        )
    }
}

export default CheckInResult;